var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "signplugin__vorgang"
  }, [_c("h1", [_vm._v("Signierung starten")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__input-mit-label"
  }, [_c("label", [_vm._v("Vorgangsbezeichnung (optional)")]), _vm._v(" "), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vorgangsbezeichnung,
      expression: "vorgangsbezeichnung"
    }],
    attrs: {
      type: "text",
      disabled: _vm.loading,
      autocomplete: "off"
    },
    domProps: {
      value: _vm.vorgangsbezeichnung
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.vorgangsbezeichnung = $event.target.value;
      }
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__input-mit-label"
  }, [_c("label", [_vm._v("Passwort für Zustellung (optional)")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__input-group"
  }, [_vm.passwordInputType === "checkbox" ? _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "signplugin__input",
    attrs: {
      disabled: _vm.loading,
      autocomplete: _vm.passwordInputAutocomplete,
      type: "checkbox"
    },
    domProps: {
      checked: Array.isArray(_vm.password) ? _vm._i(_vm.password, null) > -1 : _vm.password
    },
    on: {
      change: function change($event) {
        var $$a = _vm.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.password = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.password = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.password = $$c;
        }
      }
    }
  }) : _vm.passwordInputType === "radio" ? _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "signplugin__input",
    attrs: {
      disabled: _vm.loading,
      autocomplete: _vm.passwordInputAutocomplete,
      type: "radio"
    },
    domProps: {
      checked: _vm._q(_vm.password, null)
    },
    on: {
      change: function change($event) {
        _vm.password = null;
      }
    }
  }) : _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "signplugin__input",
    attrs: {
      disabled: _vm.loading,
      autocomplete: _vm.passwordInputAutocomplete,
      type: _vm.passwordInputType
    },
    domProps: {
      value: _vm.password
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.password = $event.target.value;
      }
    }
  }), _vm._v(" "), _c("Button", {
    attrs: {
      disabled: _vm.loading,
      text: _vm.passwordButtonText
    },
    on: {
      click: function click($event) {
        _vm.passwordVisible = !_vm.passwordVisible;
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_vm.passwordVisible ? _c("EyeIcon", {
          attrs: {
            size: 20
          }
        }) : _vm._e(), _vm._v(" "), !_vm.passwordVisible ? _c("EyeOffIcon", {
          attrs: {
            size: 20
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c("span", {
    staticClass: "signplugin__input-tooltip"
  }, [_vm._v("Wird verwendet, um fertig unterzeichnete PDF-Dokumente zu schützen, wenn diese per Email verschickt werden.")])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__absender"
  }, [_c("div", {
    staticClass: "signplugin__vorgang__absender__icon"
  }, [_c("SendIcon", {
    attrs: {
      size: 20
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__absender__text"
  }, [_c("label", [_vm._v("Absender")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.absender))])])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__empfaenger"
  }, [_c("div", {
    staticClass: "signplugin__vorgang__empfaenger__header"
  }, [_c("div", {
    staticClass: "signplugin__vorgang__empfaenger__icon"
  }, [_c("EmpfaengerIcon", {
    attrs: {
      size: 20
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__empfaenger__text"
  }, [_vm._v("\n\t\t\t\tEmpfänger\n\t\t\t")])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__empfaenger__data"
  }, _vm._l(_vm.empfaenger, function (e, index) {
    return _c("Empfaenger", {
      key: "signplugin__vorgang__empfaenger__data-".concat(index),
      class: _vm.getEmpfaengerCssClass(e),
      attrs: {
        disabled: _vm.loading,
        empfaenger: e,
        vorherigerEmpfaenger: _vm.empfaenger[index - 1] || null,
        naechsterEmpfaenger: _vm.empfaenger[index + 1] || null,
        index: index
      },
      on: {
        loeschen: _vm.loeschen,
        verknuepfen: _vm.verknuepfen,
        entknuepfen: _vm.entknuepfen,
        verschiebenHoch: _vm.verschiebenHoch,
        verschiebenRunter: _vm.verschiebenRunter
      }
    });
  }), 1)]), _vm._v(" "), _c("Button", {
    attrs: {
      wide: true,
      disabled: _vm.loading,
      text: "Empfänger hinzufügen"
    },
    on: {
      click: _vm.addSigner
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c("AccountPlusIcon", {
          attrs: {
            size: 20
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _vm._v("\n\t\tEmpfänger hinzufügen\n\t")]), _vm._v(" "), _vm.kosten.length ? _c("div", {
    staticClass: "signplugin__vorgang__kosten signplugin__color__blue"
  }, [_c("div", {
    staticClass: "signplugin__vorgang__kosten__icon"
  }, [_c("CurrencyEurIcon", {
    attrs: {
      size: 20
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__kosten__text"
  }, [_c("label", [_vm._v("Kosten, die für diesen Vorgang entstehen")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.kosten))])])]) : _vm._e(), _vm._v(" "), _vm.initiierungFehlgeschlagen ? _c("p", {
    staticClass: "signplugin__vorgang__initiierung-fehlgeschlagen signplugin__color__red"
  }, [_vm._v("\n\t\tDie Initiierung des Vorgangs ist fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben und versuchen es erneut.\n\t")]) : _vm._e(), _vm._v(" "), _vm.invalidText.length ? _c("p", {
    staticClass: "signplugin__input-tooltip signplugin__input-tooltip__red"
  }, [_vm._v(_vm._s(_vm.invalidText))]) : _vm._e(), _vm._v(" "), _c("Button", {
    class: {
      "icon-loading": _vm.loading
    },
    attrs: {
      disabled: _vm.loading,
      wide: true,
      text: "Abschließen"
    },
    on: {
      click: _vm.abschliessen
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c("PlayIcon", {
          attrs: {
            size: 20
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _vm._v("\n\t\tAbschließen\n\t")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };