function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import Unterschrift from "./Unterschrift";
import Reload from 'vue-material-design-icons/Reload.vue';
import DotsHorizontal from 'vue-material-design-icons/DotsHorizontal.vue';
import Api from "../Api";
import { NcButton as Button } from '@nextcloud/vue';
import { emit } from "@nextcloud/event-bus";
export default {
  name: "Vorgang",
  components: {
    Unterschrift: Unterschrift,
    Reload: Reload,
    DotsHorizontal: DotsHorizontal,
    Button: Button
  },
  props: {
    initialerVorgang: {
      etag: String,
      initiator: {
        email: String,
        name: String,
        uid: String
      },
      internerVorgang: {
        documentId: String,
        name: String,
        filename: String
      },
      signVorgang: {
        documentId: String,
        name: String,
        created: String,
        initiator: String,
        filename: String,
        signersCompleted: Array,
        signersRejected: Array,
        signersPending: Array
      },
      statusFertigesDokument: String,
      isDraft: Boolean,
      tableRefreshed: Boolean
    },
    fileId: Number,
    etag: String
  },
  data: function data() {
    return {
      reloading: false,
      vorgang: JSON.parse(JSON.stringify(this.initialerVorgang)),
      tableRefreshed: false
    };
  },
  methods: {
    reloadVorgang: function reloadVorgang() {
      var _this = this;
      this.reloading = true;
      Api.getVorgang(this.fileId, this.etag).then(function (response) {
        _this.vorgang = response.data;
      }).finally(function () {
        _this.reloading = false;
      });
      if (this.vorgang.statusFertigesDokument === 'DOKUMENT_GERADE_HINZUGEFUEGT') {
        // Aktualisiere Tabelle ebenfalls, wenn der "alte" Refresh-Button
        // zum Neuladen der Vorgangsdetails geklickt wird, und sich dabei
        // herausstellt, dass das Dokument mittlerweile unterschrieben ist
        emit("refreshTable", '');
        this.tableRefreshed = true;
      }
    },
    refreshTable: function refreshTable() {
      emit("refreshTable", '');
      // Nutze dieses Flag, um den Hinweis nach Buttonklick auszublenden
      this.tableRefreshed = true;
      this.reloadVorgang();
    }
  },
  computed: {
    initiator: function initiator() {
      if (this.vorgang.initiator.email) {
        return "".concat(this.vorgang.initiator.name, " (").concat(this.vorgang.initiator.email, ")");
      }
      return this.vorgang.initiator.name;
    },
    offeneUnterschriften: function offeneUnterschriften() {
      return _toConsumableArray(this.vorgang.signVorgang.signersPending || []).sort(function (a, b) {
        return a.sequence >= b.sequence ? 1 : -1;
      });
    },
    abgeschlosseneUnterschriften: function abgeschlosseneUnterschriften() {
      return [].concat(_toConsumableArray(this.vorgang.signVorgang.signersRejected || []), _toConsumableArray(this.vorgang.signVorgang.signersCompleted || [])).sort(function (a, b) {
        return a.sequence >= b.sequence ? 1 : -1;
      });
    }
  }
};