import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import MapMarkerIcon from 'vue-material-design-icons/MapMarker.vue';
import DrawIcon from 'vue-material-design-icons/Draw.vue';
import EmailIcon from 'vue-material-design-icons/Email.vue';
import EmailAtIcon from 'vue-material-design-icons/At.vue';
import MobileIcon from 'vue-material-design-icons/CellphoneBasic.vue';
export default {
  name: "UnterschriftComplete",
  components: {
    CheckCircleIcon: CheckCircleIcon,
    CancelIcon: CancelIcon,
    DrawIcon: DrawIcon,
    MapMarkerIcon: MapMarkerIcon,
    EmailIcon: EmailIcon,
    EmailAtIcon: EmailAtIcon,
    MobileIcon: MobileIcon
  },
  inject: ['appInfo'],
  props: {
    unterschrift: {
      id: String | null,
      clearName: String,
      signed: String | null,
      signedTimezone: String | null,
      email: String | null,
      mobile: String | null,
      invited: String | null,
      location: String | null,
      sequence: Number | null
    }
  },
  computed: {
    unterschriftUrl: function unterschriftUrl() {
      return "".concat(this.appInfo.apiBaseUrl, "signature/").concat(this.unterschrift.id);
    },
    unterschriftAnimiertUrl: function unterschriftAnimiertUrl() {
      return "".concat(this.appInfo.apiBaseUrl, "signature-animated/").concat(this.unterschrift.id, "?h=120");
    },
    imgId: function imgId() {
      return "signplugin__unterschrift__".concat(this.unterschrift.id);
    },
    smsEnabled: function smsEnabled() {
      return this.appInfo.smsEnabled;
    },
    signedDateTime: function signedDateTime() {
      return [typeof this.unterschrift.signed === 'string' ? this.unterschrift.signed.dateTimeGerman() : null, typeof this.unterschrift.signedTimezone === 'string' ? "(".concat(this.unterschrift.signedTimezone, ")") : null].filter(function (s) {
        return s !== null;
      }).join(' ');
    },
    einladungVerschickt: function einladungVerschickt() {
      if (this.unterschrift.invited !== null && typeof this.unterschrift.invited === "string") {
        return this.unterschrift.invited.dateTimeMenschenlesbar() || '-';
      }
      //TODO Translation
      return 'Noch nicht verschickt';
    }
  },
  mounted: function mounted() {
    var element = document.getElementById(this.imgId);
    var loadGif = function loadGif() {
      element.removeEventListener('load', loadGif);
      element.setAttribute('src', element.getAttribute('async-src'));
    };
    element.addEventListener('load', loadGif);
  }
};