export default {
  name: "FilterButtons.vue",
  props: {
    activeButton: String
  },
  data: function data() {
    return {
      status: 'ALL'
    };
  },
  methods: {
    loadProcessListForStatus: function loadProcessListForStatus(pEvent) {
      // Gesuchter Status ist Wert des Klick-Events
      this.status = pEvent.target.value;
      this.$emit('loadProcessListForStatus', this.status);
    },
    isActiveFilter: function isActiveFilter(pNewStatus) {
      // Check, welcher Statusfilter aktiv ist, um den Button zu stylen
      return pNewStatus === this.status;
    }
  }
};