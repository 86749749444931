export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      DEBUG: false
    };
  },
  methods: {
    isPageActive: function isPageActive(page) {
      return this.currentPage === page;
    },
    onClickFirstPage: function onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage: function onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage: function onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage: function onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage: function onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    }
  },
  computed: {
    isInFirstPage: function isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage: function isInLastPage() {
      return this.currentPage === this.totalPages;
    },
    startPage: function startPage() {
      // Wir sind auf der ersten Seite. Falls von aussen 0 hereinkommt, sicherstellen, dass wir Seite 1 anzeigen
      if (this.currentPage === 1 || this.currentPage <= 0) {
        if (this.DEBUG) console.log("DEBUG Pagination.vue *** startPage() Wir sind auf Seite 1 von " + this.totalPages);
        return 1;
      }
      // Wir sind auf der letzten Seite
      if (this.currentPage === this.totalPages) {
        if (this.DEBUG) console.log("DEBUG Pagination.vue *** startPage() Wir sind auf der letzten Seite von " + this.totalPages);

        // Falls im Eingabefeld ein groesserer Wert fuer die Zeilen gewaehlt wurde
        // kann das Ergebnis ggf. negativ werden. Stelle sicher, dass die Nummerierung
        // immer mit der Startseite 1 beginnt
        return this.totalPages - this.maxVisibleButtons + 1 <= 0 ? 1 : this.totalPages - this.maxVisibleButtons + 1;
      }
      // Wir sind irgendwo zwischen erster und letzter Seite
      if (this.DEBUG) console.log("DEBUG Pagination.vue *** startPage() Wir sind auf Seite " + this.currentPage + " von " + this.totalPages);
      return this.currentPage - 1;
    },
    pages: function pages() {
      // Berechne Nummerierung der Buttons zum Blaettern durch die Seiten
      var range = [];
      for (var i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i++) {
        if (this.DEBUG) console.log("DEBUG Pagination.vue *** pages([" + i + "])" + " this.startPage: " + this.startPage + " this.maxVisibleButtons: " + this.maxVisibleButtons + " this.totalPages: " + this.totalPages + "");
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }
      return range;
    }
  },
  watch: {
    currentPage: function currentPage(value) {
      if (this.DEBUG) console.log("DEBUG Pagination - NEUE Seite: " + value);
    }
  }
};