import { NcDatetimePicker as DatetimePicker } from "@nextcloud/vue";
import { emit } from "@nextcloud/event-bus";
export default {
  name: "Datepicker",
  components: {
    DatetimePicker: DatetimePicker
  },
  props: {
    parent: ''
  },
  data: function data() {
    return {
      dates: [Date, Date]
    };
  },
  methods: {
    onClickSearch: function onClickSearch() {
      // Sende Event an Vorgangsliste.vue mit Datum-von und Datum-bis
      if (this.parent === 'processlist') {
        emit('filterProcessesDate', this.getSearchDates());
      } else {
        emit('filterChildProcessesDate', this.getSearchDates());
      }
    },
    clearSearchInput: function clearSearchInput() {
      // Sende Event an Vorgangsliste.vue mit leeren Datumswerten
      emit('filterVorgangslisteDatum', "from=&to=");
    },
    getSearchDates: function getSearchDates() {
      var searchDateFrom = this.dates[0];
      var displayDateTo = this.dates[1];
      // Um im datepicker das tatsächlich gewaehlte Datum anzuzeigen,
      // kopieren wir es in diese Variable, die noch bearbeitet wird.
      var searchDateTo = new Date(displayDateTo);
      if (!!searchDateFrom) {
        // Addiere zum Datum-bis einen Tag, weil die Schnittstelle bis 00:00h exklusiv sucht
        // und das Enddatum sonst nicht mit inbegriffen wäre
        searchDateTo.setDate(displayDateTo.getDate() + 1);

        // Damit das Datum im ISO-String stimmt, muss es erst die aktuelle Zeitzone gesetzt
        // bekommen, sonst "zieht" toISOString u.U. einen Tag ab, je nach Differenz zur UTC
        var tzoffset = searchDateFrom.getTimezoneOffset() * 60000; //offset in Millisekunden
        // Formatiere das Datum in ISO-Format fuer den Suchstring
        var localISOFrom = new Date(searchDateFrom - tzoffset).toISOString().substring(0, 10);
        var localISOTo = new Date(searchDateTo - tzoffset).toISOString().substring(0, 10);

        // Baue den String fuer das Event zusammen, mit parent, um zu unterscheiden,
        // aus welcher Liste die Suche gestartet wurde
        return "from=" + localISOFrom + "&to=" + localISOTo + "&" + this.parent;
      } else {
        return "from=&to=" + "&" + this.parent;
      }
    }
  }
};