function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { NcAppContent as AppContent } from '@nextcloud/vue';
import AdminPage from "./components/AdminPage";
import EventHandler from "./components/EventHandler";
import AccountCancelIcon from "vue-material-design-icons/AccountCancel.vue";
import Api from "./Api";
import { emit, subscribe } from '@nextcloud/event-bus';
export default {
  name: 'AdminApp',
  props: {},
  components: {
    AppContent: AppContent,
    AccountCancelIcon: AccountCancelIcon,
    AdminPage: AdminPage,
    EventHandler: EventHandler
  },
  created: function created() {
    var _this = this;
    // Lade Usernamen und Adminrolle des aktuellen Benutzers,
    // um zu entscheiden, welche Vorgaenge anzuzeigen sind,
    // und ob der Button zum Filtern eingeblendet sein soll
    Api.getUserInfos().then(function (response) {
      _this.userName = response.data.userName;
      _this.currentUserIsAdmin = response.data.isAdmin;
    }).catch(function () {
      _this.userName = '';
      _this.currentUserIsAdmin = false;
    });
  },
  mounted: function mounted() {
    var _this2 = this;
    // Beim Oeffnen der Seite direkt die Liste laden
    this.loadProcessList();
    // Diese Events kommen direkt von Vorgangsliste.vue bzw. MultiprocessChildrenList.vue,
    // wo Klicks auf die Tabellennavigation oder Suche die entsprechenden Events ausloesen
    var handleEventLoadPage = function handleEventLoadPage(pPayLoad) {
      return _this2.loadProcessListPage(pPayLoad);
    };
    subscribe('loadProcessListPage', handleEventLoadPage);
    var handleEventLoadList = function handleEventLoadList(pPayLoad) {
      return _this2.loadProcessList(pPayLoad);
    };
    subscribe('loadProcessList', handleEventLoadList);
    var handleEventLoadListMulti = function handleEventLoadListMulti(pPagesizeOffsetMPKey) {
      return _this2.loadProcessListPage(pPagesizeOffsetMPKey);
    };
    subscribe('loadProcessListPageMulti', handleEventLoadListMulti);
    var handleEventLadeChildren = function handleEventLadeChildren(pMultiProcessKey) {
      return _this2.loadMultiProcessChildren(pMultiProcessKey);
    };
    subscribe('loadMultiProcessChildren', handleEventLadeChildren);
    var handleEventResetChildren = function handleEventResetChildren(pEmptyEvent) {
      return _this2.resetMultiProcessChildrenList(pEmptyEvent);
    };
    subscribe('resetMultiProcessChildrenList', handleEventResetChildren);
    var handleEventClearSearch = function handleEventClearSearch(pPageNum) {
      return _this2.clearSearch(pPageNum);
    };
    subscribe('clearSearch', handleEventClearSearch);
    var handleEventSearch = function handleEventSearch(pPayLoad) {
      return _this2.searchProcesses(pPayLoad);
    };
    subscribe('searchProcesses', handleEventSearch);

    // Dieses Event kommt von VorgangslistenEintrag beim Klick auf den Dateinamen
    var handleEventOpenFile = function handleEventOpenFile(pFileID) {
      return _this2.openFileFromNextcloud(pFileID);
    };
    subscribe('openFileFromNextcloud', handleEventOpenFile);
  },
  data: function data() {
    return {
      childProcessList: [],
      currentUserIsAdmin: false,
      filterColumns: [],
      filterColumnsMulti: [],
      filterStatus: 'ALL',
      filterStatusMulti: 'ALL',
      limit: 10,
      limitMulti: 5,
      loadForUserOnly: false,
      loading: false,
      loadMultiprocessChildren: false,
      //TODO Translation
      loadstatusText: 'Lade Vorgänge, bitte warten...',
      message: '',
      offset: 0,
      processlist: [],
      searchDateFrom: '',
      searchDateTo: '',
      searchFileName: '',
      searchInitiatorName: '',
      searchMatchType: 'LIKE',
      searchProcessName: '',
      searchSignerName: '',
      searchTerm: '',
      sortColumn: 'CREATED',
      sortOrder: 'DESC',
      sortColumnMulti: 'CREATED',
      sortOrderMulti: 'DESC',
      pageThroughFilteredList: false,
      totalResults: 0,
      totalResultsMulti: 0,
      userName: ''
    };
  },
  methods: {
    initialiseList: function initialiseList() {
      // Setzt die Liste zum Anzeigen der Vorgange zurueck, wenn keine
      // gefunden wurden
      this.processlist = [];
      this.childProcessList = [];
      this.filterStatus = 'ALL';
      this.loadMultiprocessChildren = false;
    },
    mapQueryResult: function mapQueryResult(processListJSON) {
      var _this3 = this;
      // Befuellt die Vorgangsdatentabelle mit den Feldern aus der JSON-Response
      // der Abfrage auf die Sign-API
      var processListObj = JSON.parse(processListJSON);
      var tmpVorgang;
      var tmpList = [];

      // Im JSON startet die Liste mit dem Key "Vorgaenge"
      Object.entries(processListObj.Vorgaenge).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          ID = _ref2[0],
          Vorgang = _ref2[1];
        // Fuege die Unterzeichner in einem Loop zu einem kommasepariertem String zusammen
        var signersCompleted = '';
        var signersPending = '';
        var signersRejected = '';
        // Zaehle die Unterzeichner fuer die Anzeige in der Tabelle
        var numSignersCompleted = 0;
        var numSignersRejected = 0;
        var numSignersPending = 0;
        var numSignersTotal;
        Object.entries(Vorgang.unterschrieben_von).forEach(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
            signerID = _ref4[0],
            signedVorgang = _ref4[1];
          if (signedVorgang.SignersCompleted) {
            signersCompleted += signedVorgang.SignersCompleted.clear_name;
            if (_this3.loadMultiprocessChildren) {
              var signedDate = new Date(signedVorgang.SignersCompleted.signed_on);
              signersCompleted += ", " + signedDate.toLocaleDateString("de-DE", {
                day: "numeric",
                month: "long",
                year: "numeric",
                timeZone: "Europe/Berlin"
                // TODO Translate
              }) + " um " + signedDate.toLocaleTimeString("de-DE", {
                timeZone: "Europe/Berlin"
              });
            }
            signersCompleted += ", ";
            numSignersCompleted++;
          }
          if (signedVorgang.SignersRejected) {
            signersRejected += signedVorgang.SignersRejected.clear_name;
            if (_this3.loadMultiprocessChildren) {
              var rejectedDate = new Date(signedVorgang.SignersRejected.rejected_on);
              signersRejected += ", " + rejectedDate.toLocaleDateString("de-DE", {
                day: "numeric",
                month: "long",
                year: "numeric"
                // TODO Translate
              }) + " um " + rejectedDate.toLocaleTimeString("de-DE", {
                timeZone: "Europe/Berlin"
              });
            }
            signersRejected += ', ';
            numSignersRejected++;
          }
          if (signedVorgang.SignersPending) {
            signersPending += signedVorgang.SignersPending.clear_name + ", ";
            numSignersPending++;
          }
        });
        numSignersTotal = numSignersCompleted + numSignersRejected + numSignersPending;
        // Loesche letztes Komma
        signersCompleted = signersCompleted.substring(0, signersCompleted.length - 2);
        signersRejected = signersRejected.substring(0, signersRejected.length - 2);
        signersPending = signersPending.substring(0, signersPending.length - 2);

        // Bei Multiprozessen lesen wir zusaetzlich die Werte multiTotal und multiFinished aus
        var isMulti = Vorgang.multi_process_key.length !== 0;

        // Fuelle Vorgangsstruktur mit den Daten, die wir bisher haben
        tmpVorgang = {
          'index': parseInt(parseInt(ID) + (_this3.offset + 1)),
          'etag': Vorgang.etag,
          'documentId': Vorgang.document_id,
          'fileId': Vorgang.document_id,
          'fileName': Vorgang.fileName,
          'fileType': Vorgang.fileType,
          'fileUrl': Vorgang.fileUrl,
          'initiator': {
            'uid': 'n/a',
            'name': Vorgang.erstellt_von,
            'displayName': Vorgang.erstellt_von_display_name,
            'email': 'n/a'
          },
          'internerVorgang': {
            'documentId': 'n/a',
            'name': 'n/a',
            'fileName': 'n/a'
          },
          'signVorgang': {
            'documentId': Vorgang.sign_document_id,
            'multiProcessKey': Vorgang.multi_process_key,
            'fileId': Vorgang.sign_document_file_id,
            'name': Vorgang.prozess_name,
            'fileName': Vorgang.fileName,
            'created': Vorgang.erstellt_am,
            'initiator': Vorgang.erstellt_von,
            'signersCompleted': signersCompleted,
            'signersRejected': signersRejected,
            'signersPending': signersPending,
            'numSignersCompleted': numSignersCompleted,
            'numSignersRejected': numSignersRejected,
            'numSignersPending': numSignersPending,
            'numSignersTotal': numSignersTotal,
            'multiTotal': isNaN(parseInt(Vorgang.multi_total)) ? 0 : parseInt(Vorgang.multi_total),
            'multiFinished': isNaN(parseInt(Vorgang.multi_finished)) ? 0 : parseInt(Vorgang.multi_finished),
            'lastSignerAction': Vorgang.last_signer_action,
            'cancelDate': Vorgang.cancelled
          },
          statusFertigesDokument: Vorgang.status,
          isMultiProcess: isMulti
        };
        // Vorgang in tmpList merken
        tmpList.push(tmpVorgang);
      });
      return tmpList;
    },
    loadMultiProcessChildren: function loadMultiProcessChildren(pMultiProcessKey) {
      // Zum erstmaligen Laden der Kinder eines Prozesses aus der Vorgangsliste

      // Merken, dass wir Multiprozess-Liste laden, weil mapQueryResult
      // dann die Unterzeichner anders bauen muss
      this.loadMultiprocessChildren = true;
      var payLoad = {
        limit: 5,
        offset: 0,
        sortCriteria: [],
        filterCriteria: [],
        searchCriteria: [],
        multiProcessKey: pMultiProcessKey
      };
      this.loadProcessList(payLoad);
    },
    resetMultiProcessChildrenList: function resetMultiProcessChildrenList() {
      // Beim Schliessen des Popups Instanzvariablen hier
      // auf Default setzen
      this.filterStatusMulti = 'ALL';
    },
    loadProcessListPage: function loadProcessListPage(pPayLoad) {
      // Vom Event in Vorgangsliste.vue und MultiProcessChildrenList.vue,
      // wenn in der Pagination ein Seiten-Button geklickt wird

      this.loadProcessList(pPayLoad);
    },
    loadProcessListForUser: function loadProcessListForUser() {
      // Toggle Filter der Vorgangstabelle nach aktuellem User
      this.loadForUserOnly = !this.loadForUserOnly;
      this.loadMultiprocessChildren = false;
      this.loadProcessList();
    },
    loadProcessListForStatus: function loadProcessListForStatus(pStatus) {
      this.loadMultiprocessChildren = false;
      this.filterStatus = pStatus;
      this.loadProcessList();
    },
    searchProcesses: function searchProcesses(pPayLoad) {
      this.loadMultiprocessChildren = !!pPayLoad['multiProcessKey'];
      this.loadProcessList(pPayLoad);
    },
    clearSearch: function clearSearch(pPayLoad) {
      this.searchTerm = '';
      this.pageThroughFilteredList = false;
      this.loadProcessListPage(pPayLoad);
    },
    loadProcessList: function loadProcessList(pPayLoad) {
      var _this4 = this;
      // DEFAULTs zum Laden aller Vorgaenge, wenn keine PayLoad vorhanden oder leer
      var status = this.filterStatus;
      var limit = this.limit;
      var offset = this.offset;
      var sortColumn = this.sortColumn;
      var sortOrder = this.sortOrder;
      var searchTerm = this.searchTerm;
      var searchMatchType = this.searchMatchType;
      var filterColumns = this.filterColumns;
      var multiProcessKey = '';
      var pageToLoad = 1;
      if (!!pPayLoad) {
        multiProcessKey = pPayLoad['multiProcessKey'];
        this.loadMultiprocessChildren = multiProcessKey !== '';
        limit = pPayLoad['limit'];
        pageToLoad = pPayLoad['offset'];
        if (!!pPayLoad['sortCriteria']) {
          sortColumn = pPayLoad['sortCriteria']['Column'];
          sortOrder = pPayLoad['sortCriteria']['Order'];
        }
        if (!!pPayLoad['searchCriteria']) {
          status = pPayLoad['searchCriteria']['Status'];
          searchTerm = pPayLoad['searchCriteria']['SearchTerm'];
          searchMatchType = pPayLoad['searchCriteria']['SearchMatchType'];
        }
        if (!!pPayLoad['filterCriteria']) {
          filterColumns = pPayLoad['filterCriteria'];
          this.filterColumns = filterColumns;
        } else {
          filterColumns = this.filterColumns;
        }
      }
      var isMultiProcessList = !!multiProcessKey;
      this.loading = true;
      this.loadingAppInfo = true;

      // Parameter merken fuer spaetere Requests
      if (isMultiProcessList) {
        this.limitMulti = limit === null || isNaN(limit) ? this.limitMulti : limit;
      } else {
        this.limit = limit === null || isNaN(limit) ? this.limit : limit;
      }
      offset = this.getOffsetForPage(pageToLoad, !!multiProcessKey);
      this.offset = offset === null || isNaN(offset) || offset < 0 ? this.offset : offset;

      // Pruefen, ob Status in der PayLoad vorhanden war,
      // und wenn ja, in der Instanz merken
      if (!!status) {
        if (isMultiProcessList) {
          this.filterStatusMulti = status;
        } else {
          this.filterStatus = status;
        }
      } else {
        // war nicht in der PayLoad, dann nehmen wir die
        // Instanzvariablen, sofern vorhanden
        if (!!this.filterStatus || !!this.filterStatusMulti) {
          if (isMultiProcessList) {
            status = this.filterStatusMulti;
          } else {
            status = this.filterStatus;
          }
        } else {
          status = 'ALL';
        }
      }
      var username = this.loadForUserOnly ? this.userName : '';
      this.sortColumn = sortColumn === '' ? this.sortColumn : sortColumn;
      this.sortOrder = sortOrder === '' ? this.sortOrder : sortOrder;
      this.searchMatchType = searchMatchType === '' ? this.searchMatchType : searchMatchType;
      if (!!filterColumns) {
        this.searchProcessName = filterColumns['PROCESS_NAME'];
        this.searchFileName = filterColumns['FILENAME'];
        this.searchInitiatorName = filterColumns['INITIATOR'];
        this.searchSignerName = filterColumns['SIGNERNAME'];
        this.searchDateFrom = filterColumns['DateFrom'];
        this.searchDateTo = filterColumns['DateTo'];
        // Achtung, wenn Filter gefüllt, als neuen offset 0 verwenden,
        // sonst liegt dieser ggf. über der Treffermenge
        if (!!this.searchTerm || !!this.searchProcessName || !!this.searchFileName || !!this.searchInitiatorName || !!this.searchSignerName || !!this.searchDateFrom) {
          if (!this.pageThroughFilteredList) {
            // EINMALIG auf 0 setzen, um beim Blaettern vorn zu starten
            offset = 0;
            this.offset = 0;
            this.pageThroughFilteredList = true;
            if (this.DEBUG) console.log("DEBUG AdminApp.vue:loadProcessList, Setze Offset EINMALIG auf 0 und pageThroughFilteredList auf " + this.pageThroughFilteredList);
          } else {
            if (this.DEBUG) console.log("DEBUG AdminApp.vue:loadProcessList, Filtere mit Offset " + offset + " und pageThroughFilteredList " + this.pageThroughFilteredList);
          }
        } else {
          this.pageThroughFilteredList = false;
          if (this.DEBUG) console.log("DEBUG AdminApp.vue:loadProcessList, Keine Filterkriterien: Lade normal mit Offset " + offset + " und pageThroughFilteredList " + this.pageThroughFilteredList);
        }
      }

      // Schicke den Request ans Backend
      Api.getVorgangsListe(status, username, searchTerm, this.searchDateFrom, this.searchDateTo, searchMatchType, limit, offset, sortColumn, sortOrder, this.searchProcessName, this.searchFileName, this.searchInitiatorName, this.searchSignerName, multiProcessKey).then(function (response) {
        if (response.data.returncode === 0) {
          if (isMultiProcessList) {
            // Kann auch heissen "Nichts gefunden"
            _this4.totalResultsMulti = response.data.numHits;
          } else {
            // Kann auch heissen "Nichts gefunden"
            _this4.totalResults = response.data.numHits;
          }
          if (response.data.numHits === 0) {
            // Keine Treffer!
            // Baue Dummy-Ergebnis, damit Tabelle was zurueck bekommt
            //TODO Translation
            if (isMultiProcessList) {
              _this4.childProcessList = {
                "message": "Keine Ergebnisse für Suchbegriff"
              };
            } else {
              _this4.processlist = {
                "message": "Keine Ergebnisse für Suchbegriff"
              };
            }

            // Die Message ist eine Property der Vorgangstabelle und wird oben
            // an die Komponente mit uebergeben
            //TODO Translation
            _this4.message = "Keine Vorgänge gefunden für ";
            if (!!searchTerm) {
              //TODO Translation
              _this4.message += "Suchbegriff '" + searchTerm + "' ";
            }
            if (status && status !== 'ALL') {
              if (!!searchTerm) _this4.message += " und ";
              //TODO Translation
              _this4.message += "Status '" + (status === 'FINISHED' ? "Abgeschlossen" : "Offen") + "'";
            } else if (!searchTerm) {
              //TODO Translation
              _this4.message += "diese Suchkriterien. ";
            }
          } else {
            // Mappe die Vorgaenge aus der Sign-API auf die Vorgangsstruktur
            // wie sie in der Tabelle angezeigt wird
            if (isMultiProcessList) {
              _this4.childProcessList = _this4.mapQueryResult(response.data.queryresult);
            } else {
              _this4.processlist = _this4.mapQueryResult(response.data.queryresult);
            }
          }
        } else {
          // Fehler aufgetreten

          // Fehlermeldung vom Backend in der Tabelle anzeigen
          _this4.loadstatusText = response.data.queryresult;
          _this4.initialiseList();
        }
      }).catch(function (error) {
        console.error("AdminApp.vue:loadProcessList, ERROR: [" + error + "]");
        _this4.initialiseList();
      }).finally(function () {
        _this4.loading = false;
        _this4.loadingAppInfo = false;
      });
    },
    openFileFromNextcloud: function openFileFromNextcloud(fileIDAndUserName) {
      var _this5 = this;
      // Handler fuer das Event, welches direkt von VorgangslistenEintrag kommt,
      // wenn auf die Datei geklickt wird.

      // Ist nur sinnvoll, wenn der aktuelle User auch der Initiator ist,
      // denn sonst hat er keinen Zugriff auf den Ordner mit der Datei.
      var searchTerms = fileIDAndUserName.split("&");
      var fileID = searchTerms[0];
      var initiatorLogin = searchTerms[1];
      var initiatorDisplayName = searchTerms[2];
      var fileUrl = '';
      if (this.userName !== initiatorLogin) {
        //TODO Translation
        emit('showPopupMessage', 'Dieses Dokument gehört \<strong\>' + initiatorDisplayName + '\<\/strong\> und kann deshalb nicht geöffnet werden.');
        return '';
      }
      if (fileID !== null && fileID !== '') {
        Api.getFileURL(fileID).then(function (response) {
          // Hole URL der Datei aus der Nextcloud
          if (response.data.returncode === 0) {
            fileUrl = response.data.fileURL;
            if (fileUrl.length > 0) {
              if (_this5.DEBUG) console.log("DEBUG AdminApp.vue:openFileFromNextcloud: Öffne Datei-URL " + fileUrl);
              window.open(fileUrl, '_blank', 'noreferrer');
            } else {
              //TODO Translation
              emit('showPopupMessage', 'Datei-Adresse konnte nicht ermittelt werden.\<br\/\>Kann Datei nicht öffnen.');
              console.error("AdminApp.vue:openFileFromNextcloud, Fehler beim Laden der Datei-URL: [" + response.data.message + "]");
            }
          } else {
            // Keinen URL gefunden oder Fehler aufgetreten
            console.error("AdminApp.vue:openFileFromNextcloud, Fehler beim Laden der Datei-URL: [" + response.data.message + "]");
          }
        }).catch(function (error) {
          //TODO Translation
          emit('showPopupMessage', 'Datei kann nicht geöffnet werden.\<br\/\>\<br\/\>' + error);
          console.error("AdminApp.vue:openFileFromNextcloud, ERROR: [" + error + "]");
        }).finally(function () {});
      } else {
        emit('showPopupMessage', 'Datei kann nicht geöffnet werden.\<br\/\>\<br\/\>ID der Datei nicht gefunden.');
      }
    },
    getOffsetForPage: function getOffsetForPage(pPage, pForMultiProcess) {
      // Berechnet den Offset fuer die Abfrage der Vorgangsliste
      // aus der gewaehlten Seitennummer pPage.
      var page = pPage <= 0 ? 1 : pPage;
      var limit = pForMultiProcess ? this.limitMulti : this.limit;
      if (this.DEBUG) console.log("DEBUG AdminApp.vue:getOffsetForPage multi?(" + pForMultiProcess + "): page [" + page + "], limit [" + limit + "]");
      return (page - 1) * limit;
    }
  },
  computed: {
    isCurrentUserAdmin: function isCurrentUserAdmin() {
      return this.currentUserIsAdmin === true;
    }
  }
};