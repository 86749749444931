import TrashOutlineIcon from 'vue-material-design-icons/TrashCanOutline.vue';
import PinIcon from 'vue-material-design-icons/Pin.vue';
import PinOffIcon from 'vue-material-design-icons/PinOff.vue';
import TrashIcon from 'vue-material-design-icons/TrashCan.vue';
import UpIcon from 'vue-material-design-icons/ArrowUpBoldBox.vue';
import UpOutlineIcon from 'vue-material-design-icons/ArrowUpBoldBoxOutline.vue';
import DownIcon from 'vue-material-design-icons/ArrowDownBoldBox.vue';
import DownOutlineIcon from 'vue-material-design-icons/ArrowDownBoldBoxOutline.vue';
import PinOutlineIcon from 'vue-material-design-icons/PinOutline.vue';
import PinOffOutlineIcon from 'vue-material-design-icons/PinOffOutline.vue';
import EmailIcon from 'vue-material-design-icons/At.vue';
import MobileIcon from 'vue-material-design-icons/CellphoneBasic.vue';
import CurrencyEurIcon from 'vue-material-design-icons/CurrencyEur.vue';
export default {
  name: "Empfaenger",
  components: {
    TrashIcon: TrashIcon,
    UpIcon: UpIcon,
    UpOutlineIcon: UpOutlineIcon,
    DownIcon: DownIcon,
    DownOutlineIcon: DownOutlineIcon,
    PinOutlineIcon: PinOutlineIcon,
    PinOffOutlineIcon: PinOffOutlineIcon,
    EmailIcon: EmailIcon,
    MobileIcon: MobileIcon,
    CurrencyEurIcon: CurrencyEurIcon,
    TrashOutlineIcon: TrashOutlineIcon,
    PinIcon: PinIcon,
    PinOffIcon: PinOffIcon
  },
  inject: ['appInfo'],
  emits: ['loeschen', 'verknuepfen', 'entknuepfen', 'verschiebenHoch', 'verschiebenRunter'],
  props: {
    disabled: Boolean,
    index: Number,
    vorherigerEmpfaenger: {
      contact: String,
      sequence: Number,
      name: String,
      type: "email" | "mobile"
    } || null,
    empfaenger: {
      contact: String,
      sequence: Number,
      name: String,
      type: "email" | "mobile"
    },
    naechsterEmpfaenger: {
      contact: String,
      sequence: Number,
      name: String,
      type: "email" | "mobile"
    } || null
  },
  data: function data() {
    return {
      valid: false
    };
  },
  computed: {
    tooltip: function tooltip() {
      if (this.valid) {
        return '';
      }
      if (this.smsEnabled) {
        //TODO Translation
        return 'Bitte geben Sie eine korrekte E-Mail-Adresse oder Mobilfunknummer ein.';
      }
      //TODO Translation
      return 'Bitte geben Sie eine korrekte E-Mail-Adresse ein.';
    },
    vorherigeEmpfaengerSequenceIstGleich: function vorherigeEmpfaengerSequenceIstGleich() {
      if (this.vorherigerEmpfaenger && this.vorherigerEmpfaenger.sequence) {
        return this.vorherigerEmpfaenger.sequence === this.empfaenger.sequence;
      }
      return false;
    },
    naechsteEmpfaengerSequenceIstGleich: function naechsteEmpfaengerSequenceIstGleich() {
      if (this.naechsterEmpfaenger && this.naechsterEmpfaenger.sequence) {
        return this.naechsterEmpfaenger.sequence === this.empfaenger.sequence;
      }
      return false;
    },
    hatNaechstenEmpfaenger: function hatNaechstenEmpfaenger() {
      return this.naechsterEmpfaenger !== null;
    },
    smsEnabled: function smsEnabled() {
      return this.appInfo.smsEnabled;
    },
    smsPreis: function smsPreis() {
      if (typeof this.appInfo.smsPreis === 'number') {
        //TODO Translation
        return "SMS-Kosten: ".concat(this.appInfo.smsPreis.zuLokalemPreis(), "\u20AC (netto)");
      }
      return '';
    },
    vorherigeEmpfaengerSequenceKannVerknuepftWerden: function vorherigeEmpfaengerSequenceKannVerknuepftWerden() {
      return this.vorherigerEmpfaenger && this.vorherigerEmpfaenger.sequence;
    }
  },
  watch: {
    "empfaenger.contact": function empfaengerContact(contact) {
      if (this.smsEnabled) {
        this.valid = contact.isValidMail() || contact.isValidMobile();
        this.empfaenger.type = /^[+\d ]+$/gm.test(contact) ? 'mobile' : 'email';
      } else {
        this.valid = contact.isValidMail();
      }
    }
  }
};