var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.modal ? _c("NcModal", {
    attrs: {
      closeButtonContained: true,
      title: _vm.popupTitle,
      container: null
    },
    on: {
      close: _vm.closeModal
    }
  }, [_c("div", {
    staticClass: "signplugin__modal-content",
    domProps: {
      innerHTML: _vm._s(this.popupMessage)
    }
  })]) : _vm._e(), _vm._v(" "), _vm.modalCancelReason ? _c("NcModal", {
    attrs: {
      closeButtonContained: true,
      title: _vm.cancelTitle,
      outTransition: true,
      container: null
    },
    on: {
      close: _vm.closeModalCancelReason
    }
  }, [_c("div", {
    staticClass: "signplugin__modal-textentry"
  }, [_c("label", {
    attrs: {
      for: "reason"
    }
  }, [_vm._v(_vm._s(_vm.cancelModalLabel))]), _vm._v(" "), !_vm.isProcess ? _c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isProcess ? _c("textarea", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.cancelReason,
      expression: "cancelReason"
    }],
    attrs: {
      id: "reason",
      rows: "5",
      cols: "50"
    },
    domProps: {
      value: _vm.cancelReason
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.cancelReason = $event.target.value;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c("button", {
    attrs: {
      disabled: !_vm.isValid
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s(_vm.btnText))])])]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };