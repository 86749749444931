var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "searchform"
    }
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchterm,
      expression: "searchterm"
    }],
    attrs: {
      id: "searchinput",
      type: "search",
      placeholder: _vm.placeholder
    },
    domProps: {
      value: _vm.searchterm
    },
    on: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onClickSearch.apply(null, arguments);
      },
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.searchterm = $event.target.value;
      }
    }
  }), _vm._v(" "), _c("button", {
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.onClickSearch
    }
  }, [_vm._v("Suchen")]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchterm !== "",
      expression: "searchterm !== ''"
    }],
    staticClass: "signplugin__searchinput_clear"
  }, [_c("button", {
    staticClass: "signplugin__searchinput_clear-button",
    on: {
      click: _vm.clearSearchInput
    }
  }, [_vm._v("X")])]), _vm._v(" "), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.exactMatch,
      expression: "exactMatch"
    }],
    staticClass: "signplugin__searchform-checkbox",
    attrs: {
      type: "checkbox",
      id: "searchMatchType"
    },
    domProps: {
      checked: Array.isArray(_vm.exactMatch) ? _vm._i(_vm.exactMatch, null) > -1 : _vm.exactMatch
    },
    on: {
      change: function change($event) {
        var $$a = _vm.exactMatch,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.exactMatch = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.exactMatch = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.exactMatch = $$c;
        }
      }
    }
  }), _vm._v(" "), _c("label", {
    attrs: {
      for: "checkbox"
    }
  }, [_vm._v("Exakte Suche")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };