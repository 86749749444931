var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("AppContent", [_c("EventHandler"), _vm._v(" "), _c("div", [_c("AdminPage", {
    attrs: {
      "display-process-list": _vm.processlist,
      "display-child-process-list": _vm.childProcessList,
      loadstatusText: _vm.loadstatusText,
      isAdmin: _vm.isCurrentUserAdmin,
      currentUser: _vm.userName,
      currentProcessStatus: _vm.filterStatus,
      "user-status": _vm.loadForUserOnly,
      loading: _vm.loading,
      pageSize: this.limit,
      pageSizeMulti: this.limitMulti,
      startIndex: this.offset,
      totalResults: _vm.totalResults,
      totalResultsMulti: _vm.totalResultsMulti,
      message: _vm.message
    },
    on: {
      filterForUser: _vm.loadProcessListForUser,
      ladeMultiProzessChildren: _vm.loadMultiProcessChildren
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };