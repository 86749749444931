import PauseCircleIcon from 'vue-material-design-icons/PauseCircle.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import EmailIcon from 'vue-material-design-icons/Email.vue';
import EmailAtIcon from 'vue-material-design-icons/At.vue';
import MobileIcon from 'vue-material-design-icons/CellphoneBasic.vue';
export default {
  name: "UnterschriftPending",
  components: {
    PauseCircleIcon: PauseCircleIcon,
    CancelIcon: CancelIcon,
    EmailIcon: EmailIcon,
    EmailAtIcon: EmailAtIcon,
    MobileIcon: MobileIcon
  },
  inject: ['appInfo'],
  props: {
    unterschrift: {
      clearName: String,
      email: String | null,
      mobile: String | null,
      invited: String | null,
      sequence: String | null
    }
  },
  computed: {
    smsEnabled: function smsEnabled() {
      return this.appInfo.smsEnabled;
    },
    statusText: function statusText() {
      //TODO Translation
      if (this.unterschrift.invited !== null) {
        return 'Aufgefordert';
      }
      return 'Wartet auf Vorgänger';
    },
    einladungVerschickt: function einladungVerschickt() {
      if (this.unterschrift.invited !== null && typeof this.unterschrift.invited === "string") {
        return this.unterschrift.invited.dateTimeMenschenlesbar() || '-';
      }
      //TODO Translation
      return 'Noch nicht verschickt';
    }
  }
};