String.prototype.isValidMail = function () {
  var regex = /^[\+_A-Za-z0-9-]+(\.[\+_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*((\.[A-Za-z]{2,}){1})$/gm;
  return typeof this !== 'undefined' && this.trim().length > 5 && regex.test(this);
};
String.prototype.isValidMobile = function () {
  var regex = /^[+\d ]{6,}$/gm;
  return typeof this !== 'undefined' && this.trim().length > 5 && regex.test(this);
};
Number.prototype.zuLokalemPreis = function () {
  return this.toFixed(2).replace('.', ',');
};
Number.prototype.toLeadingZeros = function (minimalDigits) {
  var digits = minimalDigits || 2;
  var diffLength = digits - this.toString().length;
  return "".concat('0'.repeat(diffLength > 0 ? diffLength : 0)).concat(this.toString());
};
Date.prototype.toGermanDate = function () {
  //getMonth() function is zero indexed based. Therefore, add 1
  var actualMonth = new Date(this).getMonth() + 1;
  return "".concat(this.getDate().toLeadingZeros(), ".").concat(actualMonth.toLeadingZeros(), ".").concat(this.getFullYear());
};
Date.prototype.toGermanUhrzeit = function () {
  return "".concat(this.getHours().toLeadingZeros(), ":").concat(this.getMinutes().toLeadingZeros());
};
Date.prototype.toGermanUhrzeitMitSekunden = function () {
  return "".concat(this.getHours().toLeadingZeros(), ":").concat(this.getMinutes().toLeadingZeros(), ":").concat(this.getSeconds().toLeadingZeros());
};
String.prototype.dateTimeGerman = function () {
  var date = new Date(this);
  if (date.toString() === 'Invalid Date') {
    return null;
  }
  return "".concat(date.toGermanDate(), " ").concat(date.toGermanUhrzeitMitSekunden());
};
String.prototype.dateTimeMenschenlesbar = function () {
  var date = new Date(this);
  if (date.toString() === 'Invalid Date') {
    return null;
  }
  var jetzt = new Date();
  var morgen = new Date();
  morgen.setDate(morgen.getDate() + 1);
  var gestern = new Date();
  gestern.setDate(gestern.getDate() - 1);
  var datum = date.toGermanDate();
  if (datum === morgen.toGermanDate()) {
    datum = "Morgen";
  } else if (datum === jetzt.toGermanDate()) {
    datum = "Heute";
  } else if (datum === gestern.toGermanDate()) {
    datum = "Gestern";
  }
  return "".concat(datum, " um ").concat(date.toGermanUhrzeit());
};