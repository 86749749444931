import { emit } from '@nextcloud/event-bus';
export default {
  name: "Searchform",
  data: function data() {
    return {
      exactMatch: false,
      //TODO Translation
      placeholder: "Vorgänge durchsuchen",
      searchterm: '',
      searchActive: false
    };
  },
  methods: {
    onClickSearch: function onClickSearch() {
      // Sende Event an Vorgangsliste.vue mit dem Suchbegriff
      this.searchActive = true;
      this.$emit('searchProcesses', this.getSearchTermAndType());
    },
    getSearchTermAndType: function getSearchTermAndType() {
      return this.searchterm + "&" + this.showMatchType();
    },
    showMatchType: function showMatchType() {
      return this.exactMatch ? 'EXACT' : 'LIKE';
    },
    clearSearchInput: function clearSearchInput() {
      this.searchterm = '';
      this.searchActive = false;
      // Sende Event an Vorgangsliste.vue
      emit('clearSearchForm', '');
    }
  },
  props: {
    numHits: 0
  },
  computed: {
    matchType: function matchType() {
      return this.exactMatch ? 'EXACT' : 'LIKE';
    }
  },
  watch: {
    exactMatch: function exactMatch(newType, oldType) {
      // In ColumnSearch.vue muss der Match-Type auch bekannt sein
      emit('changeSearchMatchType', newType);
    }
  }
};