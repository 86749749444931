import axios from '@nextcloud/axios';
import { generateUrl } from '@nextcloud/router';
var Api = {
  APP_NAME: 'signplugin',
  getAppInfos: function getAppInfos() {
    return axios.get(generateUrl("/apps/{app}/api/1.0/vorgang/infos/app", {
      app: Api.APP_NAME
    }));
  },
  getUserInfos: function getUserInfos() {
    return axios.get(generateUrl("/apps/{app}/api/1.0/vorgang/infos/user", {
      app: Api.APP_NAME
    }));
  },
  getFileURL: function getFileURL(fileId) {
    if (fileId !== null && fileId !== '') {
      return axios.get(generateUrl("/apps/{app}/api/1.0/fileUrl/{fileId}", {
        fileId: fileId,
        app: Api.APP_NAME
      }, {
        noRewrite: true,
        escape: false
      })).then(function (result) {
        if (typeof result.data.fileURL === "undefined") {
          //TODO Translation
          return Promise.reject("File with ID " + fileId + " not found.");
        } else {
          return result;
        }
      });
    }
  },
  getVorgang: function getVorgang(fileId, etag) {
    return axios.get(generateUrl("/apps/{app}/api/1.0/vorgang/{fileId}/{etag}", {
      fileId: fileId,
      etag: etag,
      app: Api.APP_NAME
    }, {
      noRewrite: true,
      escape: false
    })).then(function (result) {
      if (typeof result.data.etag === 'undefined') {
        //TODO Translation
        return Promise.reject("Something unexpected happened.");
      } else {
        return result;
      }
    });
  },
  getVorgangsListe: function getVorgangsListe(status) {
    var username = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'ALL';
    var searchterm = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var dateFrom = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    var dateTo = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    var searchMatchType = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : '';
    var limit = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : '';
    var offset = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : '';
    var sortColumn = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : '';
    var sortOrder = arguments.length > 9 && arguments[9] !== undefined ? arguments[9] : '';
    var processName = arguments.length > 10 && arguments[10] !== undefined ? arguments[10] : '';
    var fileName = arguments.length > 11 && arguments[11] !== undefined ? arguments[11] : '';
    var initiatorName = arguments.length > 12 && arguments[12] !== undefined ? arguments[12] : '';
    var signerName = arguments.length > 13 && arguments[13] !== undefined ? arguments[13] : '';
    var multiProcessKey = arguments.length > 14 && arguments[14] !== undefined ? arguments[14] : '';
    var geturl = '';
    geturl = generateUrl("/apps/{app}/api/1.0/vorgangsliste?status={status}&username={username}&searchterm={searchterm}&searchMatchType={searchMatchType}&limit={limit}&offset={offset}&sortColumn={sortColumn}&sortOrder={sortOrder}&processName={processName}&fileName={fileName}&initiatorName={initiatorName}&signerName={signerName}&dateFrom={dateFrom}&dateTo={dateTo}&multiProcessKey={multiProcessKey}", {
      status: status,
      username: username,
      searchterm: searchterm,
      dateFrom: dateFrom,
      dateTo: dateTo,
      searchMatchType: searchMatchType,
      limit: limit,
      offset: offset,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      processName: processName,
      fileName: fileName,
      initiatorName: initiatorName,
      signerName: signerName,
      multiProcessKey: multiProcessKey,
      app: Api.APP_NAME
    }, {
      noRewrite: true,
      escape: false
    });
    return axios.get(geturl).then(function (response) {
      return response;
    }).catch(function (e) {
      //TODO Translation
      console.error("Api.js:getVorgangsListe, Fehler beim Aufrufen der Schnittstelle: " + e);
      return JSON.parse('{"data":{"returncode":"1","numHits":"0","queryresult":"Fehler beim Laden der Vorgänge. Bitte verständigen Sie einen Administrator."}}');
    });
  },
  getVorgangFileInfo: function getVorgangFileInfo(etag, documentId, user) {
    if (etag && documentId && user) {
      return axios.get(generateUrl("/apps/{app}/api/1.0/vorgangfileinfo?etag={etag}&documentId={documentId}&user={user}", {
        etag: etag,
        documentId: documentId,
        user: user,
        app: Api.APP_NAME
      }, {
        noRewrite: true,
        escape: false
      })).then(function (response) {
        return response;
      }).catch(function (e) {
        //TODO Translation
        console.error("Api.js:getVorgangFileInfo, ERROR im axios.get: " + e);
      });
    } else {
      // Nicht alle erforderlichen Parameter uebergeben
      //TODO Translation
      return new Promise(function (resolve, reject) {
        reject("Missing Parameter! etag=".concat(etag, ", documentID=").concat(documentId, ", user=").concat(user));
      });
    }
  },
  initiiereVorgang: function initiiereVorgang(fileId, etag, payload) {
    return axios.post(generateUrl("/apps/{app}/api/1.0/vorgang/neu/{fileId}/{etag}", {
      etag: etag,
      fileId: fileId,
      app: Api.APP_NAME
    }, {
      noRewrite: true,
      escape: false
    }), payload);
  },
  startWizard: function startWizard(fileId, etag, payload) {
    return axios.post(generateUrl("/apps/{app}/api/1.0/start-wizard/{fileId}/{etag}", {
      fileId: fileId,
      etag: etag,
      app: Api.APP_NAME
    }, {
      noRewrite: true,
      escape: false
    }), payload);
  },
  restartWizard: function restartWizard(fileId, etag, payload) {
    return axios.post(generateUrl("/apps/{app}/api/1.0/restart-wizard/{fileId}/{etag}", {
      fileId: fileId,
      etag: etag,
      app: Api.APP_NAME
    }, {
      noRewrite: true,
      escape: false
    }), payload);
  },
  resumeWizard: function resumeWizard(processId, payload) {
    return axios.post(generateUrl("/apps/{app}/api/1.0/resume-wizard/{processId}", {
      processId: processId,
      app: Api.APP_NAME
    }, {
      noRewrite: true,
      escape: false
    }), payload);
  },
  cancelWizard: function cancelWizard(processId, payload) {
    return axios.post(generateUrl("/apps/{app}/api/1.0/cancel-wizard/{processId}", {
      processId: processId,
      app: Api.APP_NAME
    }, {
      noRewrite: true,
      escape: false
    }), payload);
  },
  cancelProcess: function cancelProcess(processId, payload) {
    return axios.post(generateUrl("/apps/{app}/api/1.0/cancel-process/{processId}", {
      processId: processId,
      app: Api.APP_NAME
    }, {
      noRewrite: true,
      escape: false
    }), payload);
  }
};
export default Api;