var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.numHits > 0 ? _c("div", {
    staticClass: "signplugin__vorgang-zeile",
    class: {
      "signplugin__highlight-row": _vm.vorgang.index === _vm.selectedRow
    }
  }, [_c("div", {
    staticClass: "signplugin__vorgang-spalte signplugin__vorgang-spalte-index"
  }, [_vm._v(_vm._s(_vm.vorgang.index))]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte-index-responsive"
  }, [_vm._v("Vorgang " + _vm._s(_vm.vorgang.index))]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte signplugin__vorgang-spalte_double"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-spaltenlabel"
  }, [_vm._v("Vorgangsbezeichnung:")]), _vm._v(" "), _vm.vorgang.signVorgang.name !== "" ? _c("span", {
    staticClass: "signplugin__vorgang-name"
  }, [_vm._v(_vm._s(_vm.vorgang.signVorgang.name))]) : _c("span", [_c("em", [_vm._v("Vorgang nicht benannt")])]), _vm._v(" "), _c("br"), _vm._v(" "), _vm.vorgang.isMultiProcess ? _c("div", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Multiprozessvorgang",
      expression: "'Multiprozessvorgang'"
    }],
    staticClass: "signplugin__vorgang-badge signplugin__vorgang-badge-MULTI"
  }, [_vm._v("\n\t\t\tMultiprozess\n\t\t")]) : _c("div", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.getTooltipForStatus(),
      expression: "getTooltipForStatus()"
    }],
    class: "signplugin__vorgang-badge " + _vm.getClassNameForStatus()
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.getStatusText(_vm.vorgang.statusFertigesDokument)) + "\n\t\t")]), _vm._v(" "), _vm.vorgang.isMultiProcess ? _c("div", [_c("span", {
    staticClass: "signplugin__vorgang-progress-text"
  }, [_c("label", {
    attrs: {
      for: "progressbar"
    }
  }, [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.vorgang.signVorgang.multiFinished) + " von " + _vm._s(_vm.vorgang.signVorgang.multiTotal) + " Prozessen abgeschlossen\n\t\t\t\t")])]), _vm._v(" "), _c("span", {
    staticClass: "signplugin__vorgang-progress-percent"
  }, [_c("progress", {
    staticClass: "signplugin__vorgang-progress",
    attrs: {
      id: "progressbar",
      max: _vm.vorgang.signVorgang.multiTotal
    },
    domProps: {
      value: _vm.vorgang.signVorgang.multiFinished
    }
  }), _vm._v("\n\t\t\t\t" + _vm._s(this.getProcessPercentage(_vm.vorgang.signVorgang.multiTotal, _vm.vorgang.signVorgang.multiFinished)) + "%\n\t\t\t")])]) : _c("div", {
    staticClass: "signplugin__vorgang_statistics"
  }, [_c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Eingeladene Unterzeichner",
      expression: "'Eingeladene Unterzeichner'"
    }]
  }, [_c("EmailOutlineIcon", {
    attrs: {
      size: 18,
      "fill-color": "#666666"
    }
  }), _vm._v(": " + _vm._s(_vm.vorgang.signVorgang.numSignersTotal))], 1), _vm._v(" "), _c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Bereits unterschrieben",
      expression: "'Bereits unterschrieben'"
    }]
  }, [_c("AccountCheckIcon", {
    attrs: {
      size: 18,
      "fill-color": "#2fb130"
    }
  }), _vm._v(": " + _vm._s(_vm.vorgang.signVorgang.numSignersCompleted))], 1), _vm._v(" "), _c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Unterschrift ausstehend",
      expression: "'Unterschrift ausstehend'"
    }]
  }, [_c("AccountClockIcon", {
    attrs: {
      size: 18,
      "fill-color": "#2e8ece"
    }
  }), _vm._v(": " + _vm._s(_vm.vorgang.signVorgang.numSignersPending))], 1), _vm._v(" "), _c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Unterschrift abgelehnt",
      expression: "'Unterschrift abgelehnt'"
    }]
  }, [_c("AccountCancelIcon", {
    attrs: {
      size: 18,
      "fill-color": "#ff0000"
    }
  }), _vm._v(": " + _vm._s(_vm.vorgang.signVorgang.numSignersRejected))], 1)])]), _vm._v(" "), _vm.isAdmin && !_vm.isMultiProcessList ? _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-spaltenlabel"
  }, [_vm._v("Empfänger:")]), _vm._v(" "), _c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.vorgang.initiator.name,
      expression: "vorgang.initiator.name"
    }]
  }, [_vm._v(_vm._s(_vm.vorgang.initiator.displayName))])]) : _vm.isMultiProcessList ? _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_vm._v("\n\t\t" + _vm._s(_vm.vorgang.signVorgang.signersCompleted) + "\n\t")]) : _vm._e(), _vm._v(" "), _vm.vorgang.statusFertigesDokument === "FINISHED" ? _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_c("div", {
    staticClass: "signplugin__vorgang-icon"
  }, [_c("FilePdfBox", {
    attrs: {
      size: 30,
      "fill-color": "#ff0000"
    }
  }), _vm._v(" "), _vm.vorgang.signVorgang.fileName.length > 0 ? _c("a", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.getTooltipForStatus(_vm.vorgang.statusFertigesDokument),
      expression: "getTooltipForStatus(vorgang.statusFertigesDokument)"
    }],
    attrs: {
      target: "_blank"
    },
    on: {
      click: function click($event) {
        return _vm.openFileFromNextcloud(_vm.vorgang.signVorgang.fileId);
      }
    }
  }, [_vm._v(_vm._s(_vm.vorgang.signVorgang.fileName) + " "), _c("OpenInNewIcon", {
    staticStyle: {
      display: "inline-flex"
    },
    attrs: {
      size: 20
    }
  })], 1) : _c("span", [_c("em", [_vm._v("Dokument nicht verfügbar")])])], 1)]) : _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-spaltenlabel"
  }, [_vm._v("Dokument:")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-icon"
  }, [_c("FilePdfBox", {
    attrs: {
      size: 30,
      "fill-color": "#ff0000"
    }
  }), _vm._v(" "), _vm.vorgang.fileName.length > 0 ? _c("a", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.getTooltipForStatus(_vm.vorgang.statusFertigesDokument),
      expression: "getTooltipForStatus(vorgang.statusFertigesDokument)"
    }],
    attrs: {
      target: "_blank"
    },
    on: {
      click: function click($event) {
        return _vm.openFileFromNextcloud(_vm.vorgang.fileId);
      }
    }
  }, [_vm._v(_vm._s(_vm.vorgang.fileName) + " "), _c("OpenInNewIcon", {
    staticStyle: {
      display: "inline-flex"
    },
    attrs: {
      size: 20
    }
  })], 1) : _c("span", [_c("em", [_vm._v("Dokument nicht verfügbar")])])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte signplugin__vorgang-spalte-date"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-spaltenlabel"
  }, [_vm._v("Erstellt am:")]), _vm._v("\n\t\t" + _vm._s(_vm.vorgang.signVorgang.created.dateTimeMenschenlesbar()) + "\n\t")]), _vm._v(" "), !_vm.isMultiProcessList ? _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-spaltenlabel"
  }, [_vm._v("Unterschriften:")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-icon"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-icon-svg"
  }, [_c("AccountCheckIcon", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Bereits unterschrieben",
      expression: "'Bereits unterschrieben'"
    }],
    attrs: {
      size: 20,
      "fill-color": "#2fb130"
    }
  })], 1), _c("span", {
    staticClass: "signplugin__vorgang-icon-text"
  }, [_vm._v(_vm._s(_vm.vorgang.signVorgang.signersCompleted))])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-icon"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-icon-svg"
  }, [_c("AccountClockIcon", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Unterschrift ausstehend",
      expression: "'Unterschrift ausstehend'"
    }],
    attrs: {
      size: 18,
      "fill-color": "#2e8ece"
    }
  })], 1), _c("span", {
    staticClass: "signplugin__vorgang-icon-text"
  }, [_vm._v(_vm._s(_vm.vorgang.signVorgang.signersPending))])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-icon"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-icon-svg"
  }, [_c("AccountCancelIcon", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Unterschrift abgelehnt",
      expression: "'Unterschrift abgelehnt'"
    }],
    attrs: {
      size: 20,
      "fill-color": "#ff0000"
    }
  })], 1), _c("span", {
    staticClass: "signplugin__vorgang-icon-text"
  }, [_vm._v(_vm._s(_vm.vorgang.signVorgang.signersRejected))])])]) : _vm._e(), _vm._v(" "), !_vm.isMultiProcessList ? _c("div", {
    staticClass: "signplugin__vorgang-spalte signplugin__vorgang-spalte-button"
  }, [_c("a", {
    staticClass: "signplugin__vorgang_actionbutton",
    on: {
      click: function click($event) {
        return _vm.$emit("details", _vm.vorgang);
      }
    }
  }, [_c("TextBoxOutlineIcon", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Details",
      expression: "'Details'"
    }],
    attrs: {
      size: 30,
      "fill-color": "#696969"
    }
  })], 1), _vm._v(" "), _vm.vorgang.statusFertigesDokument === "RUNNING" ? _c("a", {
    staticClass: "signplugin__vorgang_actionbutton",
    on: {
      click: function click($event) {
        return _vm.$emit("cancel", _vm.vorgang);
      }
    }
  }, [_c("CancelIcon", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Prozess abbrechen",
      expression: "'Prozess abbrechen'"
    }],
    attrs: {
      size: 26,
      "fill-color": "#696969"
    }
  })], 1) : _vm._e()]) : _vm._e()]) : _c("div", {
    staticClass: "signplugin__vorgang-zeile"
  }, [_c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte-noresult"
  }, [_vm._v(_vm._s(_vm.message))]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAdmin,
      expression: "isAdmin"
    }],
    staticClass: "signplugin__vorgang-spalte"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };