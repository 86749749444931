import { NcAppContent as AppContent } from "@nextcloud/vue";
import { emit } from '@nextcloud/event-bus';
import { subscribe } from '@nextcloud/event-bus';
import Vorgangsliste from "./Vorgangsliste";
import FilterButtons from "./FilterButtons.vue";
import MultiprocessChildrenList from "./MultiprocessChildrenList";
import App from "../App";
import CloseIcon from "vue-material-design-icons/Close.vue";
import NcModal from '@nextcloud/vue/dist/Components/NcModal';
export default {
  name: 'AdminPage',
  components: {
    AppContent: AppContent,
    NcModal: NcModal,
    FilterButtons: FilterButtons,
    Vorgangsliste: Vorgangsliste,
    MultiprocessChildrenList: MultiprocessChildrenList,
    CloseIcon: CloseIcon,
    App: App
  },
  mounted: function mounted() {
    var _this = this;
    // Diese Events kommen von Vorgangsliste.vue
    // Popup mit Child-Prozessen anzeigen
    var handleEventShowPopupProcessList = function handleEventShowPopupProcessList(pVorgang) {
      return _this.showPopupProcessList(pVorgang);
    };
    subscribe('showPopupProcessList', handleEventShowPopupProcessList);
  },
  data: function data() {
    return {
      status: "ALL",
      sidebarInfo: {
        index: 0,
        isActive: false,
        openEtag: '',
        openFileId: 0
      },
      cancelReason: '',
      currentPage: 1,
      currentPageMulti: 1,
      loadProcessesForUserOnly: false,
      modalProcesslist: false,
      multiTotal: 0,
      multiFinished: 0,
      multiProcessKey: ''
    };
  },
  props: {
    displayProcessList: {
      etag: String,
      documentMetaData: {
        documentId: String,
        name: String,
        created: String
      },
      documentSignStatus: {
        signersCompleted: Array,
        signersRejected: Array,
        signersPending: Array
      },
      documentOwner: {
        initiator: String
      }
    },
    displayChildProcessList: {
      etag: String,
      documentMetaData: {
        documentId: String,
        name: String,
        created: String
      },
      documentSignStatus: {
        signersCompleted: Array,
        signersRejected: Array,
        signersPending: Array
      },
      documentOwner: {
        initiator: String
      }
    },
    loading: Boolean,
    loadstatusText: String,
    currentUser: String,
    currentProcessStatus: String,
    totalResults: Number,
    totalResultsMulti: Number,
    message: String,
    pageSize: Number,
    pageSizeMulti: Number,
    startIndex: Number,
    isAdmin: false
  },
  methods: {
    loadProcessListForStatus: function loadProcessListForStatus(pStatus) {
      var payLoad = {
        limit: this.pageSize,
        offset: this.currentPage,
        sortCriteria: null,
        filterCriteria: null,
        searchCriteria: {
          'Status': pStatus
        },
        multiProcessKey: ''
      };
      emit('loadProcessList', payLoad);
    },
    loadChildProcessListForStatus: function loadChildProcessListForStatus(pStatus) {
      this.status = pStatus;
      var payLoad = {
        limit: this.pageSizeMulti,
        offset: this.currentPage,
        sortCriteria: null,
        filterCriteria: null,
        searchCriteria: {
          'Status': pStatus
        },
        multiProcessKey: this.multiProcessKey
      };
      emit('loadProcessList', payLoad);
    },
    loadUsersOwnProcesses: function loadUsersOwnProcesses() {
      // Aendere Zustand des Buttons "Nur eigene"
      this.toggleUserFilterButton();
      // Leite Event weiter an AdminApp.vue
      this.$emit('filterForUser', this.status);
    },
    toggleUserFilterButton: function toggleUserFilterButton() {
      // Flag, um Userfilter-Button an-/auszuschalten
      this.loadProcessesForUserOnly = !this.loadProcessesForUserOnly;
    },
    updateLoadingStatusText: function updateLoadingStatusText(newText) {
      // Angezeigter Text, wenn Fehler aufgetreten oder Vorgangsliste leer ist
      this.loadstatusText = newText;
    },
    showDetails: function showDetails(pVorgang) {
      emit('unhighlightRow', this.sidebarInfo.index);
      if (!pVorgang.isMultiProcess) {
        // Loese das Oeffnen der Sidebar aus.
        // Aendern dieses Objekts loest Reaktion in App.vue aus
        this.sidebarInfo = {
          index: pVorgang.index,
          isActive: true,
          openEtag: pVorgang.etag,
          openFileId: parseInt(pVorgang.documentId)
        };
      }
      // Melde Event an VorgangslistenEintrag via EventBus
      emit('highlightRow', pVorgang.index);
    },
    closeDetails: function closeDetails() {
      // Sidebar schliessen
      this.sidebarInfo.isActive = false;
      // Sende Event an VorgangslistenEintrag
      emit('unhighlightRow', this.sidebarInfo.openEtag);
    },
    showPopupProcessList: function showPopupProcessList(pVorgang) {
      // Diese Werte reichen wir ans Popup mit der Liste weiter
      this.multiTotal = pVorgang.signVorgang.multiTotal;
      this.multiFinished = pVorgang.signVorgang.multiFinished;
      this.multiProcessKey = pVorgang.signVorgang.multiProcessKey;
      //TODO Translation
      this.popupTitle = 'Prozessliste für Vorgang "' + pVorgang.signVorgang.name + '"';
      // Schicke Event an AdminApp.vue, um Liste zu laden
      emit('loadMultiProcessChildren', pVorgang.signVorgang.multiProcessKey);
      // Aktiviere Popup
      this.modalProcesslist = true;
    },
    closePopupProcessList: function closePopupProcessList() {
      this.modalProcesslist = false;
      emit('resetMultiProcessChildrenList', null);
    }
  },
  computed: {
    filterCriteria: function filterCriteria() {
      if (this.status !== "ALL") {
        //TODO Translation
        return this.status === "FINISHED" ? '"Abgeschlossen"' : '"Offen"';
      } else {
        return "";
      }
    },
    numFilteredLines: function numFilteredLines() {
      if (this.loading) {
        return "...";
      } else {
        return this.displayProcessList.length;
      }
    },
    showFilterButton: function showFilterButton() {
      return this.isAdmin === true;
    },
    calcCurrentPage: function calcCurrentPage() {
      var page = Math.ceil((this.startIndex + 1) / this.pageSize);
      //Sicherstellen, dass wir nie unter Seite 1 kommen
      return page > 0 ? page : 1;
    }
  },
  watch: {
    startIndex: function startIndex(value) {
      this.currentPage = this.calcCurrentPage;
    }
  }
};