var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "signplugin__empfaenger"
  }, [_c("div", {
    staticClass: "signplugin__empfaenger__sequence signplugin__empfaenger__border"
  }, [_c("div", {
    staticClass: "signplugin__empfaenger__sequence__strich"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__empfaenger__sequence__button"
  }, [_vm.vorherigeEmpfaengerSequenceIstGleich || !_vm.vorherigeEmpfaengerSequenceKannVerknuepftWerden || _vm.disabled ? _c("UpIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__disabled",
    attrs: {
      title: "In der Aufforderungsreihenfolge nach oben schieben aktuell nicht möglich.",
      size: 30
    }
  }) : _vm._e(), _vm._v(" "), !_vm.vorherigeEmpfaengerSequenceIstGleich && _vm.vorherigeEmpfaengerSequenceKannVerknuepftWerden && !_vm.disabled ? _c("UpOutlineIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__enabled",
    attrs: {
      title: "In der Aufforderungsreihenfolge nach oben schieben.",
      size: 30
    },
    on: {
      click: function click($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) return null;
        if ("button" in $event && $event.button !== 0) return null;
        return _vm.$emit("verschiebenHoch", _vm.index);
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "signplugin__empfaenger__sequence__strich"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__empfaenger__sequence__nummer"
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.empfaenger.sequence) + "\n\t\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__empfaenger__sequence__strich"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__empfaenger__sequence__button"
  }, [_vm.naechsteEmpfaengerSequenceIstGleich || !_vm.hatNaechstenEmpfaenger || _vm.disabled ? _c("DownIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__disabled",
    attrs: {
      title: "In der Aufforderungsreihenfolge nach unten schieben aktuell nicht möglich.",
      size: 30
    }
  }) : _vm._e(), _vm._v(" "), !_vm.naechsteEmpfaengerSequenceIstGleich && _vm.hatNaechstenEmpfaenger && !_vm.disabled ? _c("DownOutlineIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__enabled",
    attrs: {
      title: "In der Aufforderungsreihenfolge nach unten schieben.",
      size: 30
    },
    on: {
      click: function click($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) return null;
        if ("button" in $event && $event.button !== 0) return null;
        return _vm.$emit("verschiebenRunter", _vm.index);
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "signplugin__empfaenger__sequence__strich"
  })]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__empfaenger__data"
  }, [_c("div", {
    staticClass: "signplugin__empfaenger__data__toolbar"
  }, [_c("div", {
    staticClass: "signplugin__empfaenger__data__toolbar__item signplugin__empfaenger__data__toolbar__item__space"
  }), _vm._v(" "), _vm.vorherigeEmpfaengerSequenceKannVerknuepftWerden ? _c("div", {
    staticClass: "signplugin__empfaenger__data__toolbar__item"
  }, [_vm.vorherigeEmpfaengerSequenceIstGleich && !_vm.disabled ? _c("PinOffOutlineIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__enabled",
    attrs: {
      size: 30,
      title: "Der obere Empfänger und diesen wieder einzeln zur Unterschrift auffordern."
    },
    on: {
      click: function click($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) return null;
        if ("button" in $event && $event.button !== 0) return null;
        return _vm.$emit("entknuepfen", _vm.index);
      }
    }
  }) : _vm._e(), _vm._v(" "), !_vm.vorherigeEmpfaengerSequenceIstGleich && !_vm.disabled ? _c("PinOutlineIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__enabled",
    attrs: {
      size: 30,
      title: "Verbinden Sie den oberen Empfänger mit diesem, damit beide parallel zur Unterschrift aufgefordert werden."
    },
    on: {
      click: function click($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) return null;
        if ("button" in $event && $event.button !== 0) return null;
        return _vm.$emit("verknuepfen", _vm.index);
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.vorherigeEmpfaengerSequenceIstGleich && _vm.disabled ? _c("PinOffIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__disabled",
    attrs: {
      size: 30,
      title: "Der obere Empfänger und diesen wieder einzeln zur Unterschrift auffordern."
    }
  }) : _vm._e(), _vm._v(" "), !_vm.vorherigeEmpfaengerSequenceIstGleich && _vm.disabled ? _c("PinIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__disabled",
    attrs: {
      size: 30,
      title: "Verbinden Sie den oberen Empfänger mit diesem, damit beide parallel zur Unterschrift aufgefordert werden."
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.vorherigeEmpfaengerSequenceKannVerknuepftWerden ? _c("div", {
    staticClass: "signplugin__empfaenger__data__toolbar__item signplugin__empfaenger__data__toolbar__item__space"
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "signplugin__empfaenger__data__toolbar__item"
  }, [!_vm.disabled ? _c("TrashOutlineIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__enabled",
    attrs: {
      title: "Empfänger löschen",
      size: 30
    },
    on: {
      click: function click($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) return null;
        if ("button" in $event && $event.button !== 0) return null;
        return _vm.$emit("loeschen", _vm.index);
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.disabled ? _c("TrashIcon", {
    staticClass: "signplugin__empfaenger__data__toolbar__item__disabled",
    attrs: {
      title: "Empfänger löschen",
      size: 30
    }
  }) : _vm._e()], 1)]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__input-mit-label"
  }, [_c("label", [_vm._v("E-Mail" + _vm._s(_vm.smsEnabled ? "/Handynummer" : "") + " "), _c("span", {
    staticClass: "signplugin__pflichtstern"
  }, [_vm._v("*")])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__input-group"
  }, [_c("div", {
    class: "signplugin__empfaenger__contact-type ".concat(_vm.valid ? "signplugin__empfaenger__contact-type__valid" : "signplugin__empfaenger__contact-type__invalid")
  }, [_vm.empfaenger.type === "email" ? _c("EmailIcon", {
    attrs: {
      size: 20
    }
  }) : _vm._e(), _vm._v(" "), _vm.empfaenger.type === "mobile" ? _c("MobileIcon", {
    attrs: {
      size: 20
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.empfaenger.contact,
      expression: "empfaenger.contact"
    }],
    staticClass: "signplugin__input",
    attrs: {
      disabled: _vm.disabled
    },
    domProps: {
      value: _vm.empfaenger.contact
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.empfaenger, "contact", $event.target.value);
      }
    }
  })]), _vm._v(" "), _vm.tooltip.length ? _c("span", {
    staticClass: "signplugin__input-tooltip signplugin__input-tooltip__red"
  }, [_vm._v(_vm._s(_vm.tooltip))]) : _vm._e(), _vm._v(" "), _vm.empfaenger.type === "mobile" && !_vm.tooltip.length ? _c("div", {
    staticClass: "signplugin__input-tooltip signplugin__input-tooltip__blue"
  }, [_c("span", [_c("CurrencyEurIcon", {
    attrs: {
      size: 12
    }
  })], 1), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.smsPreis))])]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__input-mit-label"
  }, [_c("label", [_vm._v("Name")]), _vm._v(" "), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.empfaenger.name,
      expression: "empfaenger.name"
    }],
    attrs: {
      type: "text",
      disabled: _vm.disabled
    },
    domProps: {
      value: _vm.empfaenger.name
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.empfaenger, "name", $event.target.value);
      }
    }
  })])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };