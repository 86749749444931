import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import EmailAtIcon from 'vue-material-design-icons/At.vue';
import MobileIcon from 'vue-material-design-icons/CellphoneBasic.vue';
export default {
  name: "UnterschriftRejected",
  components: {
    CancelIcon: CancelIcon,
    EmailAtIcon: EmailAtIcon,
    MobileIcon: MobileIcon
  },
  inject: ['appInfo'],
  props: {
    unterschrift: {
      clearName: String,
      rejected: String | null,
      rejectedTimezone: String | null,
      rejectedReason: String | null,
      rejectionByCancellation: Boolean,
      email: String | null,
      mobile: String | null,
      invited: String | null,
      sequence: Number | null
    }
  },
  computed: {
    smsEnabled: function smsEnabled() {
      return this.appInfo.smsEnabled;
    },
    rejectedDateTime: function rejectedDateTime() {
      return [typeof this.unterschrift.rejected === 'string' ? this.unterschrift.rejected.dateTimeGerman() : null, typeof this.unterschrift.rejectedTimezone === 'string' ? "(".concat(this.unterschrift.rejectedTimezone, ")") : null].filter(function (s) {
        return s !== null;
      }).join(' ');
    },
    einladungVerschickt: function einladungVerschickt() {
      if (this.unterschrift.invited !== null && typeof this.unterschrift.invited === "string") {
        return this.unterschrift.invited.dateTimeMenschenlesbar() || '-';
      }
      //TODO Translation
      return 'Noch nicht verschickt';
    }
  }
};