var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "signplugin__vorgang"
  }, [_c("div", {
    staticClass: "signplugin__vorgang__header-flex"
  }, [_c("h1", [_vm._v("Informationen zum Signatur-Vorgang")]), _vm._v(" "), _c("div", [_vm.reloading && _vm.vorgang.statusFertigesDokument !== "BEREITS_ABGESPEICHERT" ? _c("DotsHorizontal", {
    staticClass: "signplugin__vorgang__dots-horizontal",
    attrs: {
      size: 25,
      title: "Informationen zum Vorgang werden neu geladen."
    }
  }) : _vm._e(), _vm._v(" "), !_vm.reloading && _vm.vorgang.statusFertigesDokument !== "BEREITS_ABGESPEICHERT" ? _c("Reload", {
    staticClass: "signplugin__vorgang__reload",
    attrs: {
      size: 25,
      title: "Informationen zum Vorgang neu laden."
    },
    on: {
      click: function click($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) return null;
        if ("button" in $event && $event.button !== 0) return null;
        return _vm.reloadVorgang();
      }
    }
  }) : _vm._e()], 1)]), _vm._v(" "), _vm.vorgang.statusFertigesDokument === "DOKUMENT_GERADE_HINZUGEFUEGT" && this.tableRefreshed === false ? _c("p", [_c("b", [_vm._v("\n\t\t\tDas unterschriebene Dokument wurde gerade zurückgespielt. Bitte laden Sie die Seite neu, um das fertige Dokument in der Dateiübersicht zu sehen.\n\t\t")]), _c("br"), _vm._v(" "), _c("Button", {
    on: {
      click: _vm.refreshTable
    }
  }, [_vm._v("Tabelle aktualisieren")])], 1) : _vm._e(), _vm._v(" "), _c("h2", [_vm._v("Initiator")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__initiator"
  }, [_c("p", [_vm._v(_vm._s(_vm.initiator))])]), _vm._v(" "), _c("h2", [_vm._v("Weitere Details")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__weitere-details"
  }, [_vm.vorgang.signVorgang.name.length ? _c("div", [_c("label", [_vm._v("Vorgangsbezeichnung")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.vorgang.signVorgang.name))])]) : _vm._e(), _vm._v(" "), _c("div", [_c("label", [_vm._v("Erstellt am")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.vorgang.signVorgang.created.dateTimeMenschenlesbar()))])])]), _vm._v(" "), _c("h2", [_vm._v("Unterschriften")]), _vm._v(" "), _c("h3", [_vm._v("Offene")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__unterschriften"
  }, _vm._l(_vm.offeneUnterschriften, function (u, index) {
    return _c("Unterschrift", {
      key: "signplugin__vorgang__offene-unterschrift-".concat(index),
      attrs: {
        unterschrift: u
      }
    });
  }), 1), _vm._v(" "), _vm.offeneUnterschriften.length < 1 ? _c("p", {
    staticClass: "signplugin__vorgang__keine-unterschriften"
  }, [_vm._v("Keine offenen Unterschriften")]) : _vm._e(), _vm._v(" "), _c("h3", [_vm._v("Abgeschlossene")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang__unterschriften"
  }, _vm._l(_vm.abgeschlosseneUnterschriften, function (u, index) {
    return _c("Unterschrift", {
      key: "signplugin__vorgang__abgeschlossene-unterschrift-".concat(index),
      attrs: {
        unterschrift: u
      }
    });
  }), 1), _vm._v(" "), _vm.abgeschlosseneUnterschriften.length < 1 ? _c("p", {
    staticClass: "signplugin__vorgang__keine-unterschriften"
  }, [_vm._v("Keine abgeschlossenen Unterschriften")]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };