import { emit } from '@nextcloud/event-bus';
import { subscribe } from '@nextcloud/event-bus';
import FilePdfBox from "vue-material-design-icons/FilePdfBox";
import AccountCheckIcon from 'vue-material-design-icons/AccountCheck.vue';
import AccountClockIcon from 'vue-material-design-icons/AccountClock.vue';
import AccountCancelIcon from 'vue-material-design-icons/AccountCancel.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import TextBoxOutlineIcon from 'vue-material-design-icons/TextBoxOutline.vue';
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew.vue';
import PenIcon from 'vue-material-design-icons/Pen.vue';
import EmailOutlineIcon from 'vue-material-design-icons/EmailOutline.vue';
import Unterschrift from "./Unterschrift";
export default {
  name: "VorgangslistenEintrag",
  components: {
    FilePdfBox: FilePdfBox,
    AccountCancelIcon: AccountCancelIcon,
    AccountClockIcon: AccountClockIcon,
    AccountCheckIcon: AccountCheckIcon,
    CancelIcon: CancelIcon,
    EmailOutlineIcon: EmailOutlineIcon,
    OpenInNewIcon: OpenInNewIcon,
    PenIcon: PenIcon,
    TextBoxOutlineIcon: TextBoxOutlineIcon,
    Unterschrift: Unterschrift
  },
  props: {
    isAdmin: Boolean,
    isMultiProcessList: Boolean,
    numHits: Number,
    message: String,
    vorgang: {
      index: Number,
      etag: String,
      documentId: Number,
      fileId: Number,
      fileName: String,
      fileType: String,
      initiator: {
        uid: String,
        name: String,
        displayName: String,
        email: String
      },
      internerVorgang: {
        documentId: String,
        name: String,
        filename: String
      },
      signVorgang: {
        documentId: String,
        name: String,
        filename: String,
        created: Date,
        initiator: String,
        signersCompleted: String,
        signersRejected: String,
        signersPending: String,
        cancelDate: String
      },
      statusFertigesDokument: String,
      isMultiProcess: Boolean,
      cancelDate: String
    }
  },
  mounted: function mounted() {
    var _this = this;
    // Diese Events kommen direkt von AdminPage.vue, wo der EventBus
    // eingerichtet ist und die Events bei Klick gesendet werden.
    var handleEventHighlight = function handleEventHighlight(pSelectedRow) {
      return _this.highlightRow(pSelectedRow);
    };
    var handleEventUnHighlight = function handleEventUnHighlight(pSelectedRow) {
      return _this.unhighlightRow(pSelectedRow);
    };
    subscribe('highlightRow', handleEventHighlight);
    subscribe('unhighlightRow', handleEventUnHighlight);
    this.selectedRow = '';
  },
  data: function data() {
    return {
      // Das Etag des angeklickten/markierten Vorgangs
      selectedRow: ''
    };
  },
  methods: {
    getProcessPercentage: function getProcessPercentage(numTotal, numFinished) {
      return Math.floor(numFinished / numTotal * 100);
    },
    getStatusText: function getStatusText(origStatus) {
      //TODO Translation
      return origStatus === "FINISHED" ? "Abgeschlossen" : origStatus === "CANCELLED" ? "Abgebrochen" : "Offen";
    },
    getClassNameForStatus: function getClassNameForStatus() {
      var status = this.vorgang.statusFertigesDokument;
      return status === "FINISHED" ? "signplugin__vorgang-badge-FINISHED" : status === "RUNNING" ? "signplugin__vorgang-badge-RUNNING" : "signplugin__vorgang-badge-CANCELLED";
    },
    getTooltipForStatus: function getTooltipForStatus() {
      // Bei Multiprozessen haben wir noch keine Daten zu den tatsaechlich
      // ausstehenden Unterschriften ueber die Anzahl hinaus
      if (this.vorgang.isMultiProcess !== false) {
        //TODO Translation
        return "Zur Einsicht der ausstehenden Signaturen bitte Detailansicht öffnen";
      }
      var num = this.vorgang.signVorgang.numSignersPending;
      return this.vorgang.statusFertigesDokument === "FINISHED" ?
      //TODO Translation
      "Das Dokument ist vollständig unterschrieben." : num === 1 ? "Es fehlt noch eine Unterschrift" : num === 0 ? "Dieser Vorgang wurde abgebrochen am " + this.vorgang.signVorgang.cancelDate : "Es fehlen noch " + num + " Unterschriften ";
    },
    openFileFromNextcloud: function openFileFromNextcloud(fileID) {
      // Baue Link zur Datei erst, wenn sie angeklickt wird,
      // um sicher zu sein, immer den aktuellen Pfad zu haben.
      // Dieses Event wird direkt in AdminApp.vue aufgefangen.
      // Um dort zu vergleichen, ob der Initiator des Vorgangs
      // gleich dem aktuellen User ist, müssen wir den hier mit
      // an den Parameter anhängen.
      var fileIDPlusUser = fileID + "&" + this.vorgang.initiator.name + "&" + this.vorgang.initiator.displayName;
      // Eigentlich ist der zweite Parameter ein Object, geht aber auch mit String
      emit("openFileFromNextcloud", fileIDPlusUser);
    },
    highlightRow: function highlightRow(selectedEtag) {
      // Aktuelles Etag merken
      this.selectedRow = selectedEtag;
    },
    unhighlightRow: function unhighlightRow(selectedEtag) {
      // Aktuelle Zeile ist nicht mehr selektiert
      if (selectedEtag === this.vorgang.etag) this.selectedRow = '';
    }
  },
  computed: {}
};