var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.numHits > 0 ? _c("div", {
    staticClass: "signplugin__vorgang-zeile",
    class: {
      "signplugin__highlight-row": _vm.vorgang.index === _vm.selectedRow
    }
  }, [_c("div", {
    staticClass: "signplugin__vorgang-spalte signplugin__vorgang-spalte-index"
  }, [_vm._v(_vm._s(_vm.vorgang.index))]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_c("div", {
    staticClass: "signplugin__vorgang-icon"
  }, [_c("FilePdfBox", {
    attrs: {
      size: 30,
      "fill-color": "#ff0000"
    }
  }), _vm._v(" "), _vm.vorgang.fileName.length > 0 ? _c("a", {
    attrs: {
      target: "_blank"
    },
    on: {
      click: function click($event) {
        return _vm.openFileFromNextcloud(_vm.vorgang.fileId);
      }
    }
  }, [_vm._v(_vm._s(_vm.vorgang.fileName) + " "), _c("OpenInNewIcon", {
    staticStyle: {
      display: "inline-flex"
    },
    attrs: {
      size: 20
    }
  })], 1) : _c("span", [_c("em", [_vm._v("Dokument nicht verfügbar")])])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_vm._v("\n\t\t" + _vm._s(this.getSignerName(_vm.vorgang.signVorgang.signersPending)) + "\n\t\t" + _vm._s(this.getSignerName(_vm.vorgang.signVorgang.signersCompleted)) + "\n\t\t" + _vm._s(this.getSignerName(_vm.vorgang.signVorgang.signersRejected)) + "\n\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte signplugin__vorgang-spalte-date"
  }, [_vm._v("\n\t\t" + _vm._s(this.formatDate(_vm.vorgang.signVorgang.lastSignerAction)) + "\n\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte signplugin__vorgang-spalte_double"
  }, [_c("div", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.getTooltipForStatus(),
      expression: "getTooltipForStatus()"
    }],
    class: "signplugin__vorgang-badge " + _vm.getClassNameForStatus()
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.getStatusText(_vm.vorgang.statusFertigesDokument)) + "\n\t\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang_statistics"
  }, [_c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Eingeladene Unterzeichner",
      expression: "'Eingeladene Unterzeichner'"
    }]
  }, [_c("EmailOutlineIcon", {
    attrs: {
      size: 18,
      "fill-color": "#666666"
    }
  }), _vm._v(": " + _vm._s(_vm.vorgang.signVorgang.numSignersTotal))], 1), _vm._v(" "), _c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Bereits unterschrieben",
      expression: "'Bereits unterschrieben'"
    }]
  }, [_c("AccountCheckIcon", {
    attrs: {
      size: 18,
      "fill-color": "#2fb130"
    }
  }), _vm._v(": " + _vm._s(_vm.vorgang.signVorgang.numSignersCompleted))], 1), _vm._v(" "), _c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Unterschrift ausstehend",
      expression: "'Unterschrift ausstehend'"
    }]
  }, [_c("AccountClockIcon", {
    attrs: {
      size: 18,
      "fill-color": "#2e8ece"
    }
  }), _vm._v(": " + _vm._s(_vm.vorgang.signVorgang.numSignersPending))], 1), _vm._v(" "), _c("span", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Unterschrift abgelehnt",
      expression: "'Unterschrift abgelehnt'"
    }]
  }, [_c("AccountCancelIcon", {
    attrs: {
      size: 18,
      "fill-color": "#ff0000"
    }
  }), _vm._v(": " + _vm._s(_vm.vorgang.signVorgang.numSignersRejected))], 1)])])]) : _c("div", {
    staticClass: "signplugin__vorgang-zeile"
  }, [_c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte-noresult"
  }, [_vm._v(_vm._s(_vm.message))]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte signplugin__vorgang-spalte_double"
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };