var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("EventHandler"), _vm._v(" "), _c("div", {
    class: {
      "icon-loading": _vm.loading || _vm.loadingAppInfo,
      signplugin__app__loading: _vm.loading || _vm.loadingAppInfo
    }
  }, [!_vm.loading && !_vm.loadingAppInfo ? _c("div", [_vm.vorgang === null ? _c("div", [_vm.nochNichtGestartet ? _c("div", {
    staticClass: "signplugin__button-container",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("Button", {
    attrs: {
      disabled: _vm.loading
    },
    on: {
      click: _vm.start
    }
  }, [_vm._v("Jetzt starten")])], 1) : _vm._e(), _vm._v(" "), !_vm.nochNichtGestartet ? _c("div", {
    staticClass: "signplugin__button-container",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("Reload", {
    staticClass: "signplugin__app__reload",
    attrs: {
      size: 50,
      title: "Informationen zu dieser Datei aktualisieren."
    },
    on: {
      click: function click($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) return null;
        if ("button" in $event && $event.button !== 0) return null;
        return _vm.reload.apply(null, arguments);
      }
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.vorgang !== null && _vm.vorgang.isDraft === true ? _c("div", {
    staticClass: "signplugin__notice"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "signplugin__button-container"
  }, [_c("Button", {
    attrs: {
      disabled: _vm.loading
    },
    on: {
      click: _vm.resumeWizard
    }
  }, [_vm._v("Wizard fortsetzen")]), _vm._v(" "), _c("Button", {
    attrs: {
      disabled: _vm.loading
    },
    on: {
      click: _vm.restartWizard
    }
  }, [_vm._v("Wizard neu starten")]), _vm._v(" "), _c("Button", {
    attrs: {
      disabled: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.cancelWizard(_vm.vorgang);
      }
    }
  }, [_vm._v("Wizard abbrechen")])], 1)]) : _vm._e(), _vm._v(" "), _vm.vorgang !== null && _vm.vorgang.isDraft === false && _vm.vorgang.statusFertigesDokument === "DOKUMENT_NOCH_NICHT_FERTIG" ? _c("div", {
    staticClass: "signplugin__notice"
  }, [_c("div", {
    staticClass: "signplugin__button-container"
  }, [_c("button", {
    attrs: {
      disabled: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.cancelProcess(_vm.vorgang);
      }
    }
  }, [_c("CancelIcon", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Vorgang abbrechen",
      expression: "'Vorgang abbrechen'"
    }],
    attrs: {
      size: 20,
      "fill-color": "#696969"
    }
  }), _c("span", [_vm._v("Vorgang abbrechen")])], 1)])]) : _vm._e(), _vm._v(" "), _vm.vorgang === null && _vm.signBackendReachable && !_vm.wizard && _vm.clickSource !== "AdminPage" ? _c("VorgangErstellen", {
    attrs: {
      etag: _vm.etag,
      fileId: _vm.fileId
    },
    on: {
      vorgangErstellt: _vm.getVorgang
    }
  }) : _vm.vorgang === null && _vm.signBackendReachable && !_vm.wizard && _vm.clickSource === "AdminPage" ? _c("div", {
    staticClass: "signplugin__notice"
  }, [_c("p", {
    staticStyle: {
      "font-size": "1.1em",
      "margin-bottom": "10px"
    }
  }, [_vm._v("Für diesen Vorgang liegen leider keine Daten vor.")]), _vm._v(" "), _c("p", [_vm._v("Möglicherweise wurde er auf einer anderen Nextcloud-Umgebung erstellt.")])]) : _vm._e(), _vm._v(" "), _vm.vorgang !== null && _vm.signBackendReachable ? _c("Vorgang", {
    attrs: {
      initialerVorgang: _vm.vorgang,
      etag: _vm.etag,
      fileId: _vm.fileId
    }
  }) : _vm._e(), _vm._v(" "), !_vm.signBackendReachable ? _c("SignBackendNotReachable") : _vm._e()], 1) : _vm._e()])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_vm._v("Es existiert bereits ein Wizard-Prozess für dieses Dokument."), _c("br"), _c("br"), _vm._v("Was möchten Sie tun?")]);
}];
render._withStripped = true;
export { render, staticRenderFns };