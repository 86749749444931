function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import Api from "../Api";
import { NcButton as Button, NcActionInput as ActionInput } from '@nextcloud/vue';
import PlayIcon from 'vue-material-design-icons/Play.vue';
import AccountPlusIcon from 'vue-material-design-icons/AccountPlus.vue';
import EyeIcon from 'vue-material-design-icons/Eye.vue';
import SendIcon from 'vue-material-design-icons/Send.vue';
import EmpfaengerIcon from 'vue-material-design-icons/AccountCircle.vue';
import EyeOffIcon from 'vue-material-design-icons/EyeOff.vue';
import CurrencyEurIcon from 'vue-material-design-icons/CurrencyEur.vue';
import Empfaenger from "./Empfaenger";
export default {
  name: "VorgangErstellen",
  inject: ['appInfo'],
  emits: ['vorgangErstellt'],
  components: {
    ActionInput: ActionInput,
    Button: Button,
    Empfaenger: Empfaenger,
    PlayIcon: PlayIcon,
    AccountPlusIcon: AccountPlusIcon,
    EyeIcon: EyeIcon,
    EyeOffIcon: EyeOffIcon,
    EmpfaengerIcon: EmpfaengerIcon,
    CurrencyEurIcon: CurrencyEurIcon,
    SendIcon: SendIcon
  },
  props: {
    etag: String,
    fileId: Number
  },
  mounted: function mounted() {
    var _this = this;
    Api.getUserInfos().then(function (response) {
      _this.emailAddress = response.data.emailAddress || "";
      _this.displayName = response.data.displayName || "";
    }).catch(function () {
      _this.emailAddress = "";
      _this.displayName = "";
    });
  },
  data: function data() {
    return {
      loading: false,
      initiierungFehlgeschlagen: false,
      password: "",
      vorgangsbezeichnung: "",
      passwordVisible: false,
      empfaenger: [{
        contact: "",
        sequence: 1,
        name: "",
        type: "email"
      }],
      displayName: "",
      emailAddress: "",
      abschliessenClicked: false
    };
  },
  methods: {
    getEmpfaengerCssClass: function getEmpfaengerCssClass(empfaenger) {
      return "signplugin__vorgang__empfaenger__data-item signplugin__vorgang__empfaenger__data-item-".concat(empfaenger.sequence % 5 + 1);
    },
    abschliessen: function abschliessen(event) {
      var _this2 = this;
      if (!this.valid && !this.abschliessenClicked) {
        this.abschliessenClicked = true;
      }
      if (this.valid) {
        this.loading = true;
        this.initiierungFehlgeschlagen = false;
        var payload = {
          name: this.vorgangsbezeichnung,
          generatePassword: false,
          pdfPassword: this.password,
          mobileRecipients: this.empfaenger.filter(function (e) {
            return e.type === "mobile";
          }).map(function (e) {
            return {
              mobile: e.contact,
              sequence: e.sequence,
              clearName: e.name
            };
          }),
          emailRecipients: this.empfaenger.filter(function (e) {
            return e.type === "email";
          }).map(function (e) {
            return {
              email: e.contact,
              sequence: e.sequence,
              clearName: e.name
            };
          })
        };
        Api.initiiereVorgang(this.fileId, this.etag, payload).then(function (response) {
          _this2.$emit('vorgangErstellt');
        }).catch(function (error) {
          _this2.initiierungFehlgeschlagen = true;
        }).finally(function () {
          _this2.loading = false;
        });
      }
    },
    addSigner: function addSigner(event) {
      // Finde den Maximumwert der Sequence und zähle eins hinzu,
      // um eine unique ID fuer den Emfpaenger-Array zu bekommen.
      // Falls der Array leer ist, nimm 1 als Default.
      var sequence = this.empfaenger.map(function (e) {
        return e.sequence;
      }).reduce(function (p, v) {
        return p > v ? p : v;
      }, 1) + 1;
      this.empfaenger.push({
        contact: "",
        sequence: sequence,
        name: "",
        type: "email"
      });
    },
    loeschen: function loeschen(index) {
      this.empfaenger.splice(index, 1);
      this.normalisiereSequence();
      this.sortiereNachSequence();
    },
    verknuepfen: function verknuepfen(index) {
      this.empfaenger[index].sequence = this.empfaenger[index - 1].sequence;
      this.normalisiereSequence();
      this.sortiereNachSequence();
    },
    entknuepfen: function entknuepfen(index) {
      this.empfaenger[index].sequence = this.empfaenger[index].sequence + 0.5;
      this.normalisiereSequence();
      this.sortiereNachSequence();
    },
    verschiebenHoch: function verschiebenHoch(index) {
      var sequenceUnten = this.empfaenger[index].sequence;
      var sequenceOben = this.empfaenger[index - 1].sequence;
      this.verschiebe(sequenceOben, sequenceUnten);
      this.sortiereNachSequence();
    },
    verschiebenRunter: function verschiebenRunter(index) {
      var sequenceUnten = this.empfaenger[index + 1].sequence;
      var sequenceOben = this.empfaenger[index].sequence;
      this.verschiebe(sequenceOben, sequenceUnten);
      this.sortiereNachSequence();
    },
    verschiebe: function verschiebe(oben, unten) {
      this.empfaenger = this.empfaenger.map(function (e) {
        return _objectSpread(_objectSpread({}, e), {}, {
          sequence: function () {
            if (e.sequence === unten) {
              return oben;
            }
            if (e.sequence === oben) {
              return unten;
            }
            return e.sequence;
          }()
        });
      });
    },
    normalisiereSequence: function normalisiereSequence() {
      // Entferne doppelte sequence-Werte und gib jedem Empfaenger eine eindeutige
      // sequence-ID in richtiger Reihenfolge und ohne Luecken
      var sequences = this.empfaenger.map(function (e) {
        return e.sequence;
      }).filter(function (value, index, values) {
        return values.indexOf(value) === index;
      });
      this.empfaenger = this.empfaenger.map(function (e) {
        return _objectSpread(_objectSpread({}, e), {}, {
          sequence: sequences.indexOf(e.sequence) + 1
        });
      });
    },
    sortiereNachSequence: function sortiereNachSequence() {
      this.empfaenger = this.empfaenger.sort(function (a, b) {
        // Fuer Firefox
        if (a.sequence === b.sequence) {
          return 0;
        }
        return a.sequence < b.sequence ? -1 : 1;
      });
    }
  },
  computed: {
    passwordButtonText: function passwordButtonText() {
      //TODO Translation
      if (this.passwordVisible) {
        return "Passwort verbergen";
      }
      return "Passwort anzeigen";
    },
    passwordInputType: function passwordInputType() {
      if (this.passwordVisible) {
        return "text";
      }
      return "password";
    },
    passwordInputAutocomplete: function passwordInputAutocomplete() {
      if (this.passwordVisible) {
        return "off";
      }
      return "new-password";
    },
    absender: function absender() {
      return "".concat(this.displayName, " ").concat(this.emailAddress.length ? "(".concat(this.emailAddress.trim(), ")") : '').trim();
    },
    valid: function valid() {
      return this.empfaenger.every(function (e) {
        return e.contact.isValidMobile() || e.contact.isValidMail();
      });
    },
    kosten: function kosten() {
      var smsEmpfaenger = this.empfaenger.filter(function (e) {
        return e.type === 'mobile' && e.contact.isValidMobile();
      }).length;
      var smsKosten = smsEmpfaenger * this.appInfo.smsPreis;
      if (smsEmpfaenger > 0 && typeof smsKosten === 'number' && typeof this.appInfo.vorgangPreis === 'number') {
        var gesamtPreis = (smsKosten + this.appInfo.vorgangPreis).zuLokalemPreis();
        return "".concat(this.appInfo.vorgangPreis.zuLokalemPreis(), " \u20AC + SMS: ").concat(smsKosten.zuLokalemPreis(), " \u20AC = \u2211 ").concat(gesamtPreis, " \u20AC");
      } else if (typeof this.appInfo.vorgangPreis === 'number') {
        return "".concat(this.appInfo.vorgangPreis.zuLokalemPreis(), " \u20AC");
      }
      return '';
    },
    invalidText: function invalidText() {
      //TODO Translation
      if (!this.valid && this.abschliessenClicked) {
        return "Ein oder mehrere Empfänger sind ungültig. Bitte überprüfen Sie Ihre Eingabe.";
      }
      return "";
    }
  },
  watch: {
    valid: function valid(value) {
      if (value) {
        this.abschliessenClicked = false;
      }
    }
  }
};