var _name$components$prop;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { emit } from '@nextcloud/event-bus';
import { subscribe } from '@nextcloud/event-bus';
import FilePdfBox from "vue-material-design-icons/FilePdfBox";
import AccountCheckIcon from 'vue-material-design-icons/AccountCheck.vue';
import AccountClockIcon from 'vue-material-design-icons/AccountClock.vue';
import AccountCancelIcon from 'vue-material-design-icons/AccountCancel.vue';
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew.vue';
import PenIcon from 'vue-material-design-icons/Pen.vue';
import EmailOutlineIcon from 'vue-material-design-icons/EmailOutline.vue';
import Unterschrift from "./Unterschrift";
export default (_name$components$prop = {
  name: "VorgangslistenEintrag",
  components: {
    FilePdfBox: FilePdfBox,
    AccountCancelIcon: AccountCancelIcon,
    AccountClockIcon: AccountClockIcon,
    AccountCheckIcon: AccountCheckIcon,
    OpenInNewIcon: OpenInNewIcon,
    PenIcon: PenIcon,
    EmailOutlineIcon: EmailOutlineIcon,
    Unterschrift: Unterschrift
  },
  props: {
    isAdmin: Boolean,
    isMultiProcessList: Boolean,
    numHits: Number,
    message: String,
    vorgang: {
      index: Number,
      etag: String,
      documentId: Number,
      fileId: Number,
      fileName: String,
      fileType: String,
      initiator: {
        uid: String,
        name: String,
        displayName: String,
        email: String
      },
      internerVorgang: {
        documentId: String,
        name: String,
        filename: String
      },
      signVorgang: {
        documentId: String,
        name: String,
        filename: String,
        created: Date,
        initiator: String,
        signersCompleted: String,
        signersRejected: String,
        signersPending: String
      },
      statusFertigesDokument: String,
      isMultiProcess: Boolean
    }
  },
  mounted: function mounted() {
    var _this = this;
    // Diese Events kommen direkt von AdminPage.vue, wo der EventBus
    // eingerichtet ist und die Events bei Klick gesendet werden.
    var handleEventHighlight = function handleEventHighlight(pSelectedRow) {
      return _this.highlightRow(pSelectedRow);
    };
    subscribe('highlightRow', handleEventHighlight);
    var handleEventUnHighlight = function handleEventUnHighlight(pSelectedRow) {
      return _this.unhighlightRow(pSelectedRow);
    };
    subscribe('unhighlightRow', handleEventUnHighlight);
  },
  data: function data() {
    return {
      // Das Etag des angeklickten/markierten Vorgangs
      selectedRow: '',
      DEBUG: false
    };
  },
  methods: {
    formatDate: function formatDate(pISODate) {
      if (this.DEBUG) console.log("DEBUG MultiprocessChild.vue:formatDate(), Input = " + pISODate);
      if (!!pISODate) {
        var date = new Date(pISODate);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        if (this.DEBUG) console.log("DEBUG MultiprocessChild.vue:formatDate(), Einzelteile = " + day + ", " + month + ", " + year + ", " + hours + ", " + minutes);
        // TODO Translation
        return "".concat(day < 10 ? '0' + day : day, ".").concat(month < 10 ? '0' + month : month, ".").concat(year, " um ").concat(hours, ":").concat(minutes < 10 ? '0' + minutes : minutes);
      } else {
        return '';
      }
    },
    getStatusText: function getStatusText(origStatus) {
      //TODO Translation
      return origStatus === "FINISHED" ? "Abgeschlossen" : "Offen";
    },
    getSignerName: function getSignerName(signer) {
      return signer.split(',')[0];
    },
    getSignerDate: function getSignerDate(signer) {
      return signer.split(',')[1];
    },
    getClassNameForStatus: function getClassNameForStatus() {
      var status = this.vorgang.statusFertigesDokument;
      return status === "FINISHED" ? "signplugin__vorgang-badge-FINISHED" : "signplugin__vorgang-badge-RUNNING";
    },
    getTooltipForStatus: function getTooltipForStatus() {
      var num = this.vorgang.signVorgang.numSignersPending;
      return this.vorgang.statusFertigesDokument === "FINISHED" ?
      //TODO Translation
      "Das Dokument ist vollständig unterschrieben." : num === 1 ? "Es fehlt noch eine Unterschrift" : "Es fehlen noch " + num + " Unterschriften ";
    },
    openFileFromNextcloud: function openFileFromNextcloud(fileID) {
      // Baue Link zur Datei erst, wenn sie angeklickt wird,
      // um sicher zu sein, immer den aktuellen Pfad zu haben.
      // Dieses Event wird direkt in AdminApp.vue aufgefangen.
      // Um dort zu vergleichen, ob der Initiator des Vorgangs
      // gleich dem aktuellen User ist, müssen wir den hier mit
      // an den Parameter anhängen.
      var fileIDPlusUser = fileID + "&" + this.vorgang.initiator.name + "&" + this.vorgang.initiator.displayName;
      // Eigentlich ist der zweite Parameter ein Object, geht aber auch mit String
      emit("openFileFromNextcloud", fileIDPlusUser);
    },
    highlightRow: function highlightRow(selectedEtag) {
      // Aktuelles Etag merken
      this.selectedRow = selectedEtag;
    },
    unhighlightRow: function unhighlightRow(selectedEtag) {
      // Aktuelle Zeile ist nicht mehr selektiert
      if (selectedEtag === this.vorgang.etag) this.selectedRow = '';
    }
  }
}, _defineProperty(_name$components$prop, "mounted", function mounted() {
  this.selectedRow = '';
}), _defineProperty(_name$components$prop, "computed", {}), _name$components$prop);