import { emit } from '@nextcloud/event-bus';
import { subscribe } from "@nextcloud/event-bus";
import CloseIcon from "vue-material-design-icons/Close.vue";
export default {
  name: "ColumnSearch",
  components: {
    CloseIcon: CloseIcon
  },
  mounted: function mounted() {
    var _this = this;
    // Wenn oben im Suchformular "Exakte Suche" angeklickt wird,
    // soll das auch fuer diese Suche gelten
    var handleEventChangeSearchMatchType = function handleEventChangeSearchMatchType(pNewType) {
      return _this.changeSearchMatchType(pNewType);
    };
    subscribe('changeSearchMatchType', handleEventChangeSearchMatchType);
  },
  data: function data() {
    return {
      //TODO Translation
      placeholder: "Spalte durchsuchen",
      searchterm: '',
      matchType: 'LIKE'
    };
  },
  methods: {
    onClickSearch: function onClickSearch() {
      if (this.parent === 'processlist') {
        //Sende Event an Vorgangsliste.vue
        emit('filterProcesses', this.getSearchColumnAndTerm());
      } else {
        // Sende Event an MultiprocessChildrenList
        emit('filterChildProcesses', this.getSearchColumnAndTerm());
      }
    },
    getSearchColumnAndTerm: function getSearchColumnAndTerm() {
      return this.column + "&" + this.searchterm + "&" + this.matchType + "&" + this.parent;
    },
    clearSearchInput: function clearSearchInput() {
      this.searchterm = '';
      if (this.parent === 'processlist') {
        emit('filterProcesses', this.getSearchColumnAndTerm());
      } else {
        emit('filterChildProcesses', this.getSearchColumnAndTerm());
      }
    },
    changeSearchMatchType: function changeSearchMatchType(pNewType) {
      this.matchType = pNewType === true ? 'EXACT' : 'LIKE';
    }
  },
  props: {
    column: '',
    numHits: 0,
    parent: ''
  }
};