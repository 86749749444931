import MultiprocessChild from "./MultiprocessChild";
import Pagination from "./Pagination";
import FilterButtons from "./FilterButtons.vue";
import ColumnSearch from "./ColumnSearch.vue";
import Datepicker from "./Datepicker";
import { emit, subscribe } from "@nextcloud/event-bus";
var ActionButtons;
export default {
  components: {
    ColumnSearch: ColumnSearch,
    Datepicker: Datepicker,
    MultiprocessChild: MultiprocessChild,
    Pagination: Pagination,
    FilterButtons: FilterButtons
  },
  name: "MultiprocessChildrenList",
  mounted: function mounted() {
    var _this = this;
    // Dieses Event kommt vom Suchfeld in den Spaltenheadern
    var handleEventFilterProcesses = function handleEventFilterProcesses(pColumnAndSearchTerm) {
      return _this.filterChildProcesses(pColumnAndSearchTerm);
    };
    subscribe('filterChildProcesses', handleEventFilterProcesses);
    var handleEventFilterProcessesDate = function handleEventFilterProcessesDate(pColumnAndSearchTerm) {
      return _this.filterChildProcessesDate(pColumnAndSearchTerm);
    };
    subscribe('filterChildProcessesDate', handleEventFilterProcessesDate);
  },
  data: function data() {
    return {
      pageSize: this.initialPageSize,
      currentPage: 1,
      sortColumn: '',
      sortOrder: 'desc',
      columnFilter: [['PROCESS_NAME', ''], ['SIGNERNAME', ''], ['FILENAME', ''], ['LAST_SIGNER_ACTION', ''], ['DateFrom', ''], ['DateTo', ''], ['Source', '']],
      sortCriteria: [['Column', ''], ['Order', 'desc']],
      searchCriteria: [['Status', 'ALL'], ['SearchTerm', ''], ['SearchMatchType', 'LIKE']],
      DEBUG: false
    };
  },
  props: {
    initialPageSize: Number,
    totalResults: Number,
    message: String,
    isAdmin: Boolean,
    title: String,
    multiTotal: Number,
    multiFinished: Number,
    multiProcessKey: String,
    childProcessList: {
      index: Number,
      etag: String,
      fileId: Number,
      documentId: Number,
      multiProcessKey: String,
      fileName: String,
      fileUrl: String,
      initiator: {
        uid: String,
        name: String,
        displayName: String,
        email: String
      },
      internerVorgang: {
        documentId: String,
        name: String,
        filename: String
      },
      signVorgang: {
        multiProcessKey: String,
        name: String,
        filename: String,
        created: Date,
        initiator: String,
        signersCompleted: String,
        signersRejected: String,
        signersPending: String
      },
      statusFertigesDokument: String
    }
  },
  methods: {
    getProcessPercentage: function getProcessPercentage(numTotal, numFinished) {
      return Math.floor(numFinished / numTotal * 100);
    },
    recalculatePages: function recalculatePages() {
      this.currentPage = 1;
      var parsedPageSize = parseInt(this.pageSize);
      if (!isNaN(parsedPageSize)) {
        this.pageSize = parsedPageSize;
        if (this.DEBUG) console.log("DEBUG MultiprocessChildrenList.vue *** recalculate() Lade Seite mit neuer Zeilenzahl = " + parsedPageSize + " und multiProcessKey " + this.multiProcessKey);
        var payLoad = {
          limit: this.pageSize,
          offset: this.currentPage,
          sortCriteria: this.sortCriteria,
          filterCriteria: this.columnFilter,
          searchCriteria: this.searchCriteria,
          multiProcessKey: this.multiProcessKey
        };
        emit("loadProcessListPageMulti", payLoad);
      } else {
        if (this.DEBUG) console.log("DEBUG MultiprocessChildrenList.vue *** recalculate() Konnte Wert pageSize [" + this.pageSize + "] nicht parsen.");
        // Setze default 5, wenn Eingabe ungueltig
        this.pageSize = 5;
      }
    },
    filterChildProcesses: function filterChildProcesses(pColumnFilter) {
      if (this.DEBUG) console.log("DEBUG MultiprocessChildrenList.vue:filterProcesses - pColumnFilter = " + pColumnFilter);
      // Da wir mit dem Event nur *einen* Parameter senden koennen,
      // ist der Spaltenname mit dem Suchbegriff und dem SearchMatchType
      // durch ein '&' verkettet
      if (!!pColumnFilter) {
        var searchColumn = pColumnFilter.split("&")[0];
        var searchTerm = pColumnFilter.split("&")[1];
        var searchMatchType = pColumnFilter.split("&")[2];
        var parentComponent = pColumnFilter.split("&")[3];
        // Setze entsprechende Werte im Array columnFilter
        this.$set(this.columnFilter, searchColumn, searchTerm);
        this.$set(this.columnFilter, 'SearchMatchType', searchMatchType);
        this.$set(this.columnFilter, 'Source', parentComponent);
        if (this.DEBUG) console.log("DEBUG MultiprocessChildList.vue *** filterProcesses() - sende Event loadProcessList für " + searchColumn + "=" + this.columnFilter[searchColumn][searchTerm] + " (sollte sein: " + searchTerm + ")");
        this.numFilterColumns += 1;
        var payLoad = {
          limit: this.pageSize,
          offset: this.currentPage,
          sortCriteria: this.sortCriteria,
          filterCriteria: this.columnFilter,
          searchCriteria: this.searchCriteria,
          multiProcessKey: this.multiProcessKey
        };
        if (this.DEBUG) console.log("DEBUG MultiprocessChildList.vue *** filterProcesses() - sende Event loadProcessList mit columnFilter:");
        if (this.DEBUG) console.table(this.columnFilter);
        // Sende Event an AdminApp.vue
        emit('loadProcessList', payLoad);
      }
    },
    filterChildProcessesDate: function filterChildProcessesDate(pFromAndToDate) {
      // Datepicker.vue fuegt die Daten als String zusammen.
      // Z.B.: "from=2023-01-30&to=2023-02-03
      if (this.DEBUG) console.log("DEBUG MultiprocessChildrenList.vue *** filterChildProcessesDate() - Filtern nach [" + pFromAndToDate + "]");
      if (!!pFromAndToDate) {
        var fromDate = pFromAndToDate.split("&")[0].split("=")[1];
        var toDate = pFromAndToDate.split("&")[1].split("=")[1];
        var sourceComponent = pFromAndToDate.split("&")[2];
        // Setze entsprechende Werte im Gesamt-Array "columnFilter"
        // oder "columnFilterMulti", je nach Quelle
        this.$set(this.columnFilter, 'DateFrom', fromDate);
        this.$set(this.columnFilter, 'DateTo', toDate);
        this.$set(this.columnFilter, 'Source', sourceComponent);
        this.$set(this.columnFilter, 'MultiProcessKey', this.multiProcessKey);
        this.numFilterColumns += 1;
        var payLoad = {
          limit: this.pageSize,
          offset: this.currentPage,
          sortCriteria: this.sortCriteria,
          filterCriteria: this.columnFilter,
          searchCriteria: this.searchCriteria,
          multiProcessKey: this.multiProcessKey
        };
        if (this.DEBUG) console.log("DEBUG MultiprocessChildrenList.vue *** filterChildProcessesDate() - sende Event loadProcessList mit payLoad");
        if (this.DEBUG) console.table(payLoad);
        emit('loadProcessList', payLoad);
      }
    },
    sort: function sort(pSortColumn) {
      if (this.DEBUG) console.log("DEBUG 'MultiprocessChildren'.vue *** sort(" + pSortColumn + ") this.sortierKriterium war bisher " + this.sortCriteria['Column']);
      var sortOrder = 'asc';
      if (pSortColumn === this.sortColumn) {
        // Wenn Kriterium unveraendert, kehre Sortierreihenfolge um.
        // Die Sortierkriterien sind Properties am Datenmodell "tmpVorgang"
        // in AdminApp.vue
        sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      }
      // Sortierkriterien fuer naechsten Aufruf merken
      this.sortOrder = sortOrder;
      this.sortColumn = pSortColumn;

      // Sortierbedinungen im Array fuers Event speichern
      this.$set(this.sortCriteria, 'Column', pSortColumn);
      this.$set(this.sortCriteria, 'Order', sortOrder);

      // Veranlasse neue Suchabfrage an Sign-API mit Sortierkriterien
      // Event wird von AdminApp.vue aufgefangen.
      var payLoad = {
        limit: this.pageSize,
        offset: this.currentPage,
        sortCriteria: this.sortCriteria,
        filterCriteria: this.columnFilter,
        searchCriteria: this.searchCriteria,
        multiProcessKey: this.multiProcessKey
      };
      emit("loadProcessList", payLoad);
    },
    sortedClass: function sortedClass(pColumn) {
      // CSS-Klasse fuer die Darstellung des Icons neben dem Spaltentitel
      if (this.sortColumn !== pColumn) {
        // Nicht gewaehlte Spalten nicht markieren
        return 'hidden icon-triangle-s';
      }
      // Beim ersten Laden der Seite greift das vorbelegte Sortierkriterium "sortByDate"
      return 'icon-triangle-' + (this.sortOrder === 'asc' ? 'n' : 's');
    },
    onPageChange: function onPageChange(pPage) {
      // Setze aktuelle Seite beim Blaettern
      this.currentPage = pPage;
      // Baue Parameter fuer Eventbus
      // Event geht an AdminApp.vue
      var payLoad = {
        limit: this.pageSize,
        offset: this.currentPage,
        sortCriteria: this.sortCriteria,
        filterCriteria: this.columnFilter,
        searchCriteria: this.searchCriteria,
        multiProcessKey: this.multiProcessKey
      };
      if (this.DEBUG) console.log("DEBUG MultiprocessChildList.vue *** onPageChange() - Lade Daten für Seite " + pPage + " mit Limit " + this.pageSize);
      emit("loadProcessListPage", payLoad);
    },
    refreshTable: function refreshTable() {
      // Simuliere Seitenaenderung einfach mit der aktuellen Seite
      this.onPageChange(this.currentPage);
    },
    clearSearch: function clearSearch(pColumnFilter) {
      // Wenn pColumnFilter gefuellt, kam das Event von der Spaltensuche,
      // wenn der Parameter leer ist, kam er von dem Sucheingabefeld.
      if (!!pColumnFilter) {
        this.$set(this.columnFilter, pColumnFilter.split("&")[0], '');
        this.numFilterColumns -= 1;
        emit('loadProcessList', this.columnFilter);
      } else {
        // Aktuelle Seite einfach neu laden
        emit('clearSearch', this.currentPage >= 0 ? this.currentPage : 0);
      }
    }
  },
  computed: {
    sortierteVorgangsliste: function sortierteVorgangsliste() {
      // Frueher wurde hier die bereits geladene Liste sortiert. Jetzt gibt es
      // stattdessen eine neue Abfrage auf die Sign-API mit dem Sortierkriterium.
      // Die Funktion setzt nur noch die neue pageSize, bevor sie die processlist
      // zurueckgibt, welche mit der Response von der Sign-API befuellt wurde.
      this.newPageSize = this.pageSize;
      return this.childProcessList;
    },
    indexStart: function indexStart() {
      // Erste anzuzeigende Zeilennummer
      if (this.DEBUG) console.log("DEBUG MultiprocessChildrenList.vue *** indexStart() rechnet mit currentPage = " + this.currentPage + " und pageSize = " + this.pageSize);
      return (this.currentPage - 1) * this.pageSize;
    },
    indexEnd: function indexEnd() {
      // Letzte anzuzeigende Zeilennummer
      if (this.DEBUG) console.log("DEBUG MultiprocessChildrenList.vue *** indexEnd() rechnet mit indexStart " + this.indexStart + " und pageSize = " + this.pageSize);
      return this.indexStart + this.pageSize;
    },
    currentPageString: function currentPageString() {
      // Fuer die Ausgabe im Frontend muss die Seitenzahl ein String sein
      return '' + this.currentPage;
    },
    totalPages: function totalPages() {
      var newNumPages;
      if (this.totalResults <= this.pageSize) {
        // Vorgaenge passen alle auf eine Seite
        newNumPages = 1;
        // Aktiv aktuelle Seite setzen, um indexStart und indexEnd neu zu berechnen
        this.currentPage = 1;
      } else {
        // Nimm die naechstgroesste Seitenzahl
        newNumPages = Math.ceil(this.totalResults / this.pageSize);
      }
      if (this.DEBUG) console.log("DEBUG MultiprocessChildrenList.vue *** totalPages() pageSize = " + this.pageSize + " und totalPages = " + newNumPages);
      return newNumPages;
    },
    paginated: function paginated() {
      // Das Slicen der Liste ist obsolet. Die paginierte Liste kommt direkt von der Sign-API
      return this.childProcessList;
    },
    sortBy: function sortBy() {
      // Baue Kriterium und Richtung zu einem einzigen String fuer das Event
      // zusammen, das oben in sort() verschickt wird.
      return this.sortierKriterium + "-" + this.sortierRichtung.toUpperCase();
    }
  }
};