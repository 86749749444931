import Api from "./Api";
import Reload from 'vue-material-design-icons/Reload.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import EventHandler from "./components/EventHandler";
import Vorgang from "./components/Vorgang";
import VorgangErstellen from "./components/VorgangErstellen";
import SignBackendNotReachable from "./components/SignBackendNotReachable";
import { emit } from "@nextcloud/event-bus";
export default {
  name: 'App',
  props: {
    fileId: Number,
    etag: String,
    clickSource: String,
    wizard: Boolean,
    standAlone: Boolean
  },
  components: {
    EventHandler: EventHandler,
    CancelIcon: CancelIcon,
    Vorgang: Vorgang,
    VorgangErstellen: VorgangErstellen,
    SignBackendNotReachable: SignBackendNotReachable,
    Reload: Reload
  },
  mounted: function mounted() {
    var _this = this;
    this.getVorgang();
    Api.getAppInfos().then(function (response) {
      _this.smsEnabled = response.data.smsEnabled;
      _this.smsPreis = response.data.smsPreis;
      _this.vorgangPreis = response.data.vorgangPreis;
      _this.apiBaseUrl = response.data.apiBaseUrl;
    }).catch(function (error) {
      _this.signBackendReachable = false;
    }).finally(function () {
      _this.loadingAppInfo = false;
    });
  },
  data: function data() {
    return {
      nochNichtGestartet: true,
      cancelWizzardModal: false,
      loading: false,
      loadingAppInfo: true,
      signBackendReachable: true,
      vorgang: null,
      smsEnabled: false,
      smsPreis: null,
      vorgangPreis: null,
      apiBaseUrl: null,
      fileIDinternal: this.fileId,
      etagInternal: this.etag,
      isDraft: false,
      processId: null,
      cancelReason: ''
    };
  },
  methods: {
    start: function start() {
      var _this2 = this;
      this.loading = true;
      this.nochNichtGestartet = false;
      var wizardUrl = '';
      var draftExists = false;
      Api.startWizard(this.fileId, this.etag).then(function (response) {
        if (response.status === 200 || response.status === 204) {
          // 200 = Wir haben einen Wizard-URL
          wizardUrl = response.data.wizardUrl;
          _this2.loading = false;
          if (wizardUrl.length > 0) {
            window.open(wizardUrl, '_blank');
          } else {
            console.error("Error in startWizard: Wizard-URL ist leer");
          }
        }
      }).catch(function (error) {
        // Fallback, falls das schiefgeht: Sidebar oeffnen
        console.error("Error in startWizard: " + error.toString());
        _this2.loading = false;
      });
    },
    reload: function reload() {
      this.getVorgang();
      this.nochNichtGestartet = true;
    },
    closeCancelModal: function closeCancelModal() {
      this.cancelWizzardModal = false;
    },
    getVorgang: function getVorgang() {
      var _this3 = this;
      this.loading = true;
      this.vorgang = null;
      Api.getVorgang(this.fileIDinternal, this.etagInternal).then(function (response) {
        _this3.vorgang = response.data;
        _this3.processId = _this3.vorgang.signVorgang.documentId;
      }).catch(function (error) {
        // Ein 404, wenn es noch keinen Vorgang gibt
        _this3.vorgang = null;
      }).finally(function () {
        _this3.loading = false;
      });
    },
    cancelProcess: function cancelProcess(pProcess) {
      // Dieses Event kommt nur von Einzel-Vorgaengen im Status "RUNNING", weil nur
      // bei diesen der entsprechende Button in VorgangsListenEintrag sichtbar ist
      emit('showPopupEnterCancelReasonProcess', {
        payload: pProcess,
        callback: this.reload
      });
    },
    cancelWizard: function cancelWizard(pProcess) {
      // Dieses Event kommt nur von Einzel-Vorgaengen im Status "RUNNING", weil nur
      // bei diesen der entsprechende Button in VorgangsListenEintrag sichtbar ist
      emit('showPopupEnterCancelReasonWizzard', {
        payload: pProcess,
        callback: this.reload
      });
    },
    restartWizard: function restartWizard() {
      var _this4 = this;
      // Starte Wizard neu und raeume danach alte Eintraege aus der DB auf
      this.loading = true;
      var wizardUrl = '';
      // Schicke Request via Backend zur Sign-API, um einen URL fuer den Wizard
      // zu bekommen. Die API gibt eine Prozess-ID und den URL zurueck.
      Api.restartWizard(this.fileId, this.etag).then(function (response) {
        if (response.status === 200 || response.status === 204) {
          // 200 = Wir haben einen Wizard-URL
          wizardUrl = response.data.wizardUrl;
          if (wizardUrl.length > 0) {
            _this4.vorgang = null;
            _this4.nochNichtGestartet = false;
            window.open(wizardUrl, '_blank');
          } else {
            // TODO Translation
            emit('showPopupMessage', 'Beim Restart ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.');
            _this4.getVorgang();
          }
        }
      }).catch(function (error) {
        emit('showPopupMessage', 'Beim Restart ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.');
        _this4.getVorgang();
      }).finally(function () {
        _this4.loading = false;
      });
    },
    resumeWizard: function resumeWizard() {
      var _this5 = this;
      this.loading = true;
      var wizardUrl = '';
      // Schicke Request zum Fortsetzen des Wizards an die Sign-API, um einen neuen
      // URL zu erhalten.
      Api.resumeWizard(this.processId).then(function (response) {
        var httpstatus = response.status;
        var details = response.data.details;
        var message = response.data.message;
        if (httpstatus === 200 || httpstatus === 204) {
          wizardUrl = response.data.wizardUrl;
          if (wizardUrl.length > 0) {
            _this5.vorgang = null;
            _this5.nochNichtGestartet = false;
            window.open(wizardUrl, '_blank');
          } else {
            // Das Popup zeigt AdminPage.vue an
            // TODO Translation
            emit('showPopupMessage', 'Fehler: Keinen Zugang zu Sign erhalten. Bitte verständigen Sie einen Administrator.');
          }
        } else {
          // Das Popup zeigt AdminPage.vue an
          //TODO Translation
          emit('showPopupMessage', message + ', Status ' + httpstatus);
        }
      }).catch(function (error) {
        // Das Popup zeigt AdminPage.vue an
        //TODO Translation
        emit('showPopupMessage', 'Wizard-Prozess konnte nicht fortgesetzt werden. ERROR: ' + error.toString());
      }).finally(function () {
        _this5.loading = false;
      });
    },
    cleanupAfterWizard: function cleanupAfterWizard(cancelled) {
      Api.cleanupAfterWizard(this.fileId, this.etag, cancelled).then(function (response) {
        // Alles OK, nichts zu tun
      }).catch(function (error) {
        return false;
      });
      return true;
    }
  },
  provide: function provide() {
    var _this6 = this;
    var appInfo = {};
    Object.defineProperty(appInfo, 'smsEnabled', {
      configurable: true,
      get: function get() {
        return _this6.smsEnabled;
      }
    });
    Object.defineProperty(appInfo, 'smsPreis', {
      configurable: true,
      get: function get() {
        return _this6.smsPreis;
      }
    });
    Object.defineProperty(appInfo, 'vorgangPreis', {
      configurable: true,
      get: function get() {
        return _this6.vorgangPreis;
      }
    });
    Object.defineProperty(appInfo, 'apiBaseUrl', {
      configurable: true,
      get: function get() {
        return _this6.apiBaseUrl;
      }
    });
    return {
      appInfo: appInfo
    };
  },
  watch: {
    cancelWizzardModal: function cancelWizzardModal(val) {
      if (!val) {
        this.cancelReason = '';
      }
    },
    etag: function etag(val, oldVal) {
      this.etagInternal = val;
      this.getVorgang();
    }
  }
};