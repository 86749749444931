var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("DatetimePicker", {
    staticClass: "signplugin__datepicker-widget",
    attrs: {
      placeholder: "Zeitraum filtern",
      clearable: true,
      format: "DD.MM.YY",
      type: "date",
      range: "",
      showWeekNumber: true,
      "input-class": "signplugin__datepicker-input",
      "range-separator": " bis "
    },
    on: {
      input: _vm.onClickSearch,
      clear: _vm.clearSearchInput
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };