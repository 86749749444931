var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "signplugin__filter_zeile"
  }, [_c("div", {
    staticClass: "signplugin__filter_spalte"
  }, [_c("button", {
    class: {
      active: _vm.isActiveFilter("ALL")
    },
    attrs: {
      id: "all",
      value: "ALL",
      name: "Alle"
    },
    on: {
      click: function click($event) {
        return _vm.loadProcessListForStatus($event);
      }
    }
  }, [_vm._v("Alle")])])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__filter_zeile"
  }, [_c("div", {
    staticClass: "signplugin__filter_spalte"
  }, [_c("button", {
    class: {
      active: _vm.isActiveFilter("RUNNING")
    },
    attrs: {
      id: "created",
      value: "RUNNING",
      name: "Offen"
    },
    on: {
      click: function click($event) {
        return _vm.loadProcessListForStatus($event);
      }
    }
  }, [_vm._v("Offen")])])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__filter_zeile"
  }, [_c("div", {
    staticClass: "signplugin__filter_spalte"
  }, [_c("button", {
    class: {
      active: _vm.isActiveFilter("FINISHED")
    },
    attrs: {
      id: "finished",
      value: "FINISHED",
      name: "Abgeschlossen"
    },
    on: {
      click: function click($event) {
        return _vm.loadProcessListForStatus($event);
      }
    }
  }, [_vm._v("Abgeschlossen")])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };