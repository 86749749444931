import { NcModal } from "@nextcloud/vue";
import { emit, subscribe, unsubscribe } from "@nextcloud/event-bus";
import Api from "../Api";
export default {
  name: "EventHandler",
  components: {
    NcModal: NcModal
  },
  beforeDestroy: function beforeDestroy() {
    // Dieses Event kommt von diversen Stellen, um Popup-Nachrichten anzuzeigen
    unsubscribe('showPopupMessage', this.showPopupMessage);

    // Popup zur Eingabe eines Grundes fuer Abbruch des Prozesses
    unsubscribe('showPopupEnterCancelReasonWizzard', this.showPopupEnterCancelReasonWizzard);
    unsubscribe('showPopupEnterCancelReasonProcess', this.showPopupEnterCancelReasonProcess);
    unsubscribe('cancelProcess', this.cancelProcess);
    unsubscribe('cancelWizzard', this.cancelProcess);
  },
  mounted: function mounted() {
    // Dieses Event kommt von diversen Stellen, um Popup-Nachrichten anzuzeigen
    subscribe('showPopupMessage', this.showPopupMessage);

    // Popup zur Eingabe eines Grundes fuer Abbruch des Prozesses
    subscribe('showPopupEnterCancelReasonWizzard', this.showPopupEnterCancelReasonWizzard);
    subscribe('showPopupEnterCancelReasonProcess', this.showPopupEnterCancelReasonProcess);
    subscribe('cancelProcess', this.cancelProcess);
    subscribe('cancelWizzard', this.cancelProcess);
  },
  data: function data() {
    return {
      popupTitle: '',
      popupMessage: '',
      modal: false,
      modalCancelReason: false,
      cancelReason: '',
      isProcess: false,
      cancelId: '',
      cb: function cb() {}
    };
  },
  computed: {
    btnText: function btnText() {
      if (this.isProcess) {
        return "Prozess abbrechen";
      }
      return "Wizard abbrechen";
    },
    isValid: function isValid() {
      if (this.isProcess) {
        return this.cancelReason.trim().length > 0;
      }
      return true;
    },
    cancelTitle: function cancelTitle() {
      if (this.isProcess) {
        return 'Bitte einen Grund für den Abbruch eingeben';
      }
      return 'Wizard abbrechen';
    },
    cancelModalLabel: function cancelModalLabel() {
      if (this.isProcess) {
        return "Bitte einen Grund für den Abbruch eingeben (Pflichtangabe)";
      }
      return "Soll der Wizard wirklich abgebrochen werden?";
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.modal = false;
      this.popupTitle = '';
      this.popupMessage = '';
    },
    closeModalCancelReason: function closeModalCancelReason() {
      this.cb = function () {};
      this.modalCancelReason = false;
      this.cancelReason = '';
    },
    showPopupMessage: function showPopupMessage(pMessage) {
      this.popupMessage = pMessage;
      this.popupTitle = '';
      this.modal = true;
    },
    showPopupEnterCancelReasonWizzard: function showPopupEnterCancelReasonWizzard(evt) {
      this.cancelId = evt.payload.signVorgang.documentId;
      this.isProcess = false;
      this.cb = evt.callback;
      this.modalCancelReason = true;
    },
    showPopupEnterCancelReasonProcess: function showPopupEnterCancelReasonProcess(evt) {
      this.cancelId = evt.payload.signVorgang.documentId;
      this.cb = evt.callback;
      this.isProcess = true;
      this.modalCancelReason = true;
    },
    cancel: function cancel() {
      if (this.isProcess) {
        this.cancelProcess();
      } else {
        this.cancelWizard();
      }
    },
    cancelProcess: function cancelProcess() {
      var _this = this;
      Api.cancelProcess(this.cancelId, {
        cancelReason: this.cancelReason
      }).then(function (response) {
        if (response.status === 200 || response.status === 204) {
          // Sende Event an AdminPage
          emit('showPopupMessage', 'Vorgang erfolgreich abgebrochen');
        } else {
          emit('showPopupMessage', 'Beim Abbruch des Vorgangs ist ein Fehler aufgetreten.');
        }
      }).catch(function (error) {
        emit('showPopupMessage', 'Beim Abbruch des Vorgangs ist ein Fehler aufgetreten.');
      }).finally(function () {
        emit('loadProcessList', undefined);
        _this.cb();
        _this.closeModalCancelReason();
      });
    },
    cancelWizard: function cancelWizard() {
      var _this2 = this;
      Api.cancelWizard(this.cancelId, {
        cancelReason: this.cancelReason
      }).then(function (response) {
        if (response.status === 200 || response.status === 204) {
          // Sende Event an AdminPage
          emit('showPopupMessage', 'Wizard erfolgreich abgebrochen');
        } else {
          emit('showPopupMessage', 'Beim Abbruch des Wizards ist ein Fehler aufgetreten.');
        }
      }).catch(function (error) {
        emit('showPopupMessage', 'Beim Abbruch des Wizards ist ein Fehler aufgetreten.');
      }).finally(function () {
        emit('loadProcessList', undefined);
        _this2.cb();
        _this2.closeModalCancelReason();
      });
    }
  }
};