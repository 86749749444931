var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("AppContent", [_c("div", {
    staticClass: "signplugin__adminpage"
  }, [_c("div", {
    staticClass: "signplugin__vorgangsliste-wrapper"
  }, [_c("h2", {
    staticStyle: {
      margin: "30px 50px"
    }
  }, [_vm._v("Signierungsvorgänge " + _vm._s(_vm.filterCriteria) + " (" + _vm._s(_vm.totalResults) + ")")]), _vm._v(" "), _c("div", {
    class: {
      "icon-loading": _vm.loading,
      "signplugin__icon-loading": _vm.loading
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "signplugin__filter_tabelle-label"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("Status")])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__filter_tabelle",
    attrs: {
      id: "filterung"
    }
  }, [_c("FilterButtons", {
    staticClass: "signplugin__filter-status",
    attrs: {
      "active-button": "ALL"
    },
    on: {
      loadProcessListForStatus: _vm.loadProcessListForStatus
    }
  }), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilterButton,
      expression: "showFilterButton"
    }],
    staticClass: "signplugin__filter_zeile"
  }, [_c("div", {
    staticClass: "signplugin__filter_spalte"
  }, [_c("button", {
    class: {
      active: _vm.loadProcessesForUserOnly
    },
    attrs: {
      id: "own",
      value: "OWN",
      name: "Nur eigene"
    },
    on: {
      click: function click($event) {
        return _vm.loadUsersOwnProcesses($event);
      }
    }
  }, [_vm._v("Nur eigene")])])])], 1), _vm._v(" "), _vm.totalResults >= 0 ? _c("div", {
    staticClass: "signplugin__vorgangsliste"
  }, [_c("Vorgangsliste", {
    attrs: {
      isAdmin: _vm.showFilterButton,
      isMultiProcessList: false,
      vorgangsliste: _vm.displayProcessList,
      totalResults: _vm.totalResults,
      "initial-page-size": _vm.pageSize,
      message: _vm.message,
      "current-page-prop": this.currentPage,
      "current-status": _vm.currentProcessStatus
    },
    on: {
      details: _vm.showDetails,
      updateLadestatusText: _vm.updateLoadingStatusText
    }
  })], 1) : _c("div", {
    staticClass: "signplugin__vorgangsliste"
  }, [_c("div", [_vm._v(_vm._s(this.loadstatusText))])])]), _vm._v(" "), _vm.sidebarInfo.isActive ? _c("div", {
    staticClass: "signplugin__adminpage-sidebar signplugin__adminpage-sidebar-sticky"
  }, [_c("div", {
    staticClass: "signplugin__adminpage-sidebar_nav"
  }, [_c("button", {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: "Schließen",
      expression: "'Schließen'"
    }],
    attrs: {
      id: "closeSidebar"
    },
    on: {
      click: function click($event) {
        return _vm.closeDetails();
      }
    }
  }, [_c("CloseIcon", {
    attrs: {
      "fill-color": "#000000"
    }
  })], 1)]), _vm._v(" "), _c("App", {
    staticClass: "signplugin__adminpage-sidebar_app",
    attrs: {
      "file-id": this.sidebarInfo.openFileId,
      etag: this.sidebarInfo.openEtag,
      "click-source": "AdminPage",
      "stand-alone": false
    }
  })], 1) : _vm._e(), _vm._v(" "), this.modalProcesslist ? _c("NcModal", {
    attrs: {
      closeButtonContained: false,
      title: this.popupTitle,
      size: "full",
      outTransition: true
    },
    on: {
      close: _vm.closePopupProcessList
    }
  }, [_c("div", {
    staticClass: "signplugin__filter_tabelle-label"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("Status")])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__filter_tabelle",
    attrs: {
      id: "filterung"
    }
  }, [_c("FilterButtons", {
    staticClass: "signplugin__filter-status signplugin__filter-status-popup",
    attrs: {
      "active-button": "ALL"
    },
    on: {
      loadProcessListForStatus: _vm.loadChildProcessListForStatus
    }
  })], 1), _vm._v(" "), _vm.totalResults >= 0 ? _c("div", {
    staticClass: "signplugin__vorgangsliste"
  }, [_c("MultiprocessChildrenList", {
    attrs: {
      isAdmin: _vm.showFilterButton,
      title: _vm.popupTitle,
      isMultiProcessList: true,
      childProcessList: _vm.displayChildProcessList,
      totalResults: _vm.totalResultsMulti,
      "initial-page-size": 5,
      message: _vm.message,
      "current-page": _vm.currentPageMulti,
      "multi-process-key": this.multiProcessKey,
      "multi-total": _vm.multiTotal,
      "multi-finished": _vm.multiFinished
    },
    on: {
      updateLadestatusText: _vm.updateLoadingStatusText
    }
  })], 1) : _vm._e()]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };