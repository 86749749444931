var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isCompleted ? _c("UnterschriftComplete", {
    attrs: {
      unterschrift: _vm.unterschrift
    }
  }) : _vm.isPending ? _c("UnterschriftPending", {
    attrs: {
      unterschrift: _vm.unterschrift
    }
  }) : _vm.isRejected ? _c("UnterschriftRejected", {
    attrs: {
      unterschrift: _vm.unterschrift
    }
  }) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };