import Vue from 'vue';
import { translate as t, translatePlural as n } from '@nextcloud/l10n';
import { VTooltip } from 'v-tooltip';
import './prototype';
import AdminApp from './AdminApp';
if (document.getElementById("sign-plugin-admin-app") !== null) {
  Vue.directive('tooltip', VTooltip);

  // Adding translations to the whole app
  Vue.mixin({
    methods: {
      t: t,
      n: n
    }
  });
  Vue.prototype.OC = window.OC;
  Vue.prototype.OCA = window.OCA;
  new Vue({
    render: function render(h) {
      return h(AdminApp, {
        props: {}
      });
    }
  }).$mount('#sign-plugin-admin-app');
}