var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "columnsearch"
    }
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchterm,
      expression: "searchterm"
    }],
    attrs: {
      id: "searchinput",
      type: "search",
      placeholder: _vm.placeholder
    },
    domProps: {
      value: _vm.searchterm
    },
    on: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onClickSearch.apply(null, arguments);
      },
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.searchterm = $event.target.value;
      }
    }
  }), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchterm !== "",
      expression: "searchterm !== ''"
    }],
    staticClass: "signplugin__searchinput_clear"
  }, [_c("button", {
    staticClass: "signplugin__searchinput_clear-button",
    on: {
      click: _vm.clearSearchInput
    }
  }, [_c("CloseIcon", {
    attrs: {
      "fill-color": "#222222"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };