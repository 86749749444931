function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "vorgangstabelle-wrapper"
    }
  }, [_c("h2", {
    staticClass: "signplugin__vorgang-titelzeile"
  }, [_vm._v(_vm._s(_vm.title) + " (" + _vm._s(_vm.totalResults) + ")")]), _vm._v(" "), _c("span", {
    staticClass: "signplugin__vorgang-progress-percent"
  }, [_c("progress", {
    staticClass: "signplugin__vorgang-progress",
    attrs: {
      id: "progressbar",
      max: this.multiTotal
    },
    domProps: {
      value: this.multiFinished
    }
  }), _vm._v("\n\t\t" + _vm._s(this.getProcessPercentage(this.multiTotal, this.multiFinished)) + "%\n\t")]), _vm._v(" "), _c("Pagination", {
    staticClass: "signplugin__vorgang-pagination signplugin__vorgang-pagination-top",
    attrs: {
      currentPage: this.currentPage,
      perPage: _vm.pageSize,
      totalPages: _vm.totalPages
    },
    on: {
      pagechanged: _vm.onPageChange
    }
  }, [_vm._v(_vm._s(_vm.currentPageString))]), _vm._v(" "), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.pageSize,
      expression: "pageSize",
      modifiers: {
        number: true
      }
    }],
    staticClass: "signplugin__input",
    attrs: {
      id: "newPageSizeTop",
      type: "number"
    },
    domProps: {
      value: _vm.pageSize
    },
    on: {
      input: [function ($event) {
        if ($event.target.composing) return;
        _vm.pageSize = _vm._n($event.target.value);
      }, function ($event) {
        return _vm.recalculatePages();
      }],
      blur: function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm._v(" "), _c("label", {
    attrs: {
      for: "newPageSizeTop"
    }
  }, [_vm._v("Zeilen pro Seite")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgangstabelle"
  }, [_c("div", {
    staticClass: "signplugin__vorgang-titelzeile"
  }, [_c("div", {
    staticClass: "signplugin__vorgang-spalte-nonsortable"
  }, [_vm._v("#")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-spalte-sortable",
    on: {
      click: function click($event) {
        return _vm.sort("FILENAME");
      }
    }
  }, [_vm._v("\n\t\t\tDokument "), _c("span", {
    class: _defineProperty({
      "sort-indicator": true
    }, _vm.sortedClass("FILENAME"), true)
  })]), _vm._v(" "), _c("ColumnSearch", {
    attrs: {
      column: "FILENAME",
      parent: "processchildlist"
    },
    on: {
      filterChildProcesses: _vm.filterChildProcesses
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-spalte-sortable",
    on: {
      click: function click($event) {
        return _vm.sort("INITIATOR");
      }
    }
  }, [_vm._v("\n\t\t\t\tEmpfänger "), _c("span", {
    class: _defineProperty({
      "sort-indicator": true
    }, _vm.sortedClass("INITIATOR"), true)
  })]), _vm._v(" "), _c("ColumnSearch", {
    attrs: {
      column: "SIGNERNAME",
      parent: "processchildlist"
    },
    on: {
      filterChildProcesses: _vm.filterChildProcesses
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_c("span", {
    staticClass: "signplugin__vorgang-spalte-sortable signplugin__vorgang-spalte-date",
    on: {
      click: function click($event) {
        return _vm.sort("LAST_SIGNER_ACTION");
      }
    }
  }, [_vm._v("\n\t\t\tLetzte Aktion am "), _c("span", {
    class: _defineProperty({
      "sort-indicator": true
    }, _vm.sortedClass("LAST_SIGNER_ACTION"), true)
  })]), _vm._v(" "), _c("Datepicker", {
    staticClass: "signplugin__datepicker",
    attrs: {
      parent: "processchildlist"
    },
    on: {
      filterChildProcessesDate: _vm.filterChildProcesses
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "signplugin__vorgang-spalte"
  }, [_vm._v("\n\t\t\t\tStatus\n\t\t\t\t")])]), _vm._v(" "), _vm._l(_vm.paginated, function (vorgang) {
    return _c("MultiprocessChild", {
      key: vorgang.index,
      attrs: {
        isAdmin: _vm.isAdmin,
        vorgang: vorgang,
        "num-hits": _vm.totalResults,
        message: _vm.message
      }
    });
  })], 2), _vm._v(" "), _c("Pagination", {
    staticClass: "signplugin__vorgang-pagination signplugin__vorgang-pagination-bottom",
    attrs: {
      currentPage: this.currentPage,
      perPage: _vm.pageSize,
      totalPages: _vm.totalPages
    },
    on: {
      pagechanged: _vm.onPageChange
    }
  }, [_vm._v(_vm._s(_vm.currentPageString))]), _vm._v(" "), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.pageSize,
      expression: "pageSize",
      modifiers: {
        number: true
      }
    }],
    staticClass: "signplugin__input",
    attrs: {
      id: "newPageSizeBottom",
      type: "number"
    },
    domProps: {
      value: _vm.pageSize
    },
    on: {
      input: [function ($event) {
        if ($event.target.composing) return;
        _vm.pageSize = _vm._n($event.target.value);
      }, function ($event) {
        return _vm.recalculatePages();
      }],
      blur: function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm._v(" "), _c("label", {
    attrs: {
      for: "newPageSizeBottom"
    }
  }, [_vm._v("Zeilen pro Seite")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };