var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("ul", {
    staticClass: "signplugin__pagination"
  }, [_c("li", {
    staticClass: "signplugin__pagination-item"
  }, [_c("button", {
    attrs: {
      type: "button",
      disabled: _vm.isInFirstPage
    },
    on: {
      click: _vm.onClickFirstPage
    }
  }, [_vm._v("Anfang")])]), _vm._v(" "), _c("li", {
    staticClass: "signplugin__pagination-item"
  }, [_c("button", {
    attrs: {
      type: "button",
      disabled: _vm.isInFirstPage
    },
    on: {
      click: _vm.onClickPreviousPage
    }
  }, [_vm._v("Vorherige")])]), _vm._v(" "), _vm._l(_vm.pages, function (page) {
    return _c("li", {
      key: page.name,
      staticClass: "signplugin__pagination-item"
    }, [_c("button", {
      class: {
        active: _vm.isPageActive(page.name)
      },
      attrs: {
        type: "button",
        disabled: page.isDisabled
      },
      on: {
        click: function click($event) {
          return _vm.onClickPage(page.name);
        }
      }
    }, [_vm._v(_vm._s(page.name))])]);
  }), _vm._v(" "), _c("li", {
    staticClass: "signplugin__pagination-item"
  }, [_c("button", {
    attrs: {
      type: "button",
      disabled: _vm.isInLastPage
    },
    on: {
      click: _vm.onClickNextPage
    }
  }, [_vm._v("Nächste")])]), _vm._v(" "), _c("li", {
    staticClass: "signplugin__pagination-item"
  }, [_c("button", {
    attrs: {
      type: "button",
      disabled: _vm.isInLastPage
    },
    on: {
      click: _vm.onClickLastPage
    }
  }, [_vm._v("Ende")])])], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };