import VorgangslistenEintrag from "./VorgangslistenEintrag";
import Pagination from "./Pagination";
import Searchform from "./Searchform.vue";
import ColumnSearch from "./ColumnSearch.vue";
import Datepicker from "./Datepicker";
import { emit } from "@nextcloud/event-bus";
import { subscribe } from "@nextcloud/event-bus";
var ActionButtons;
export default {
  components: {
    Searchform: Searchform,
    ColumnSearch: ColumnSearch,
    Datepicker: Datepicker,
    VorgangslistenEintrag: VorgangslistenEintrag,
    Pagination: Pagination
  },
  name: "Vorgangsliste",
  mounted: function mounted() {
    var _this = this;
    // Dieses Event kommt vom Suchformular, wo die aktuelle Pagination nicht bekannt
    // ist. Darum muss von hier aus der Zustand von vor der Eingabe des Suchbegriffs
    // wieder hergestellt werden.
    var handleEventClearSearch = function handleEventClearSearch(param) {
      return _this.clearSearch(param);
    };
    subscribe('clearSearchForm', handleEventClearSearch);
    // Dieses Event kommt vom Suchfeld in den Spaltenheadern
    var handleEventFilterVorgaenge = function handleEventFilterVorgaenge(pColumnAndSearchTerm) {
      return _this.filterProcesses(pColumnAndSearchTerm);
    };
    subscribe('filterProcesses', handleEventFilterVorgaenge);
    // Dieses Event kommt vom Datepicker im Spaltenheader
    var handleEventFilterVorgaengeDatum = function handleEventFilterVorgaengeDatum(pFromAndToDate) {
      return _this.filterProcessesDate(pFromAndToDate);
    };
    subscribe('filterProcessesDate', handleEventFilterVorgaengeDatum);

    // Dieses Event kommt von den Vorgangsdetails in der Sidebar
    var handleEventRefreshTable = function handleEventRefreshTable(pRefresh) {
      return _this.refreshTable(pRefresh);
    };
    subscribe('refreshTable', handleEventRefreshTable);
  },
  data: function data() {
    return {
      DEBUG: false,
      sortColumn: 'CREATED',
      sortOrder: 'desc',
      pageSize: this.initialPageSize,
      currentPage: this.currentPageProp,
      columnFilter: [['PROCESS_NAME', ''], ['INITIATOR', ''], ['FILENAME', ''], ['CREATED', ''], ['DateFrom', ''], ['DateTo', ''], ['Source', '']],
      sortCriteria: [['Column', ''], ['Order', 'desc']],
      searchCriteria: [['Status', 'ALL'], ['SearchTerm', ''], ['SearchMatchType', 'LIKE']],
      numFilterColumns: 0,
      numFilterColumnsMulti: 0,
      // Speichere originale Liste, wie sie von AdminPage kam
      //vorgangslisteBackup: this.processlist,
      // Verwende diese Liste zum Anzeigen mit Sortieren, Filtern, etc.
      //vorgangslisteDisplay: this.processlist,
      numQueryHits: 0
    };
  },
  props: {
    initialPageSize: Number,
    totalResults: Number,
    message: String,
    isAdmin: Boolean,
    isMultiProcessList: Boolean,
    currentStatus: String,
    currentPageProp: Number,
    vorgangsliste: {
      index: Number,
      etag: String,
      fileId: Number,
      documentId: Number,
      fileName: String,
      fileUrl: String,
      initiator: {
        uid: String,
        name: String,
        displayName: String,
        email: String
      },
      internerVorgang: {
        documentId: String,
        name: String,
        filename: String
      },
      signVorgang: {
        documentId: String,
        name: String,
        filename: String,
        created: Date,
        initiator: String,
        signersCompleted: String,
        signersRejected: String,
        signersPending: String
      },
      statusFertigesDokument: String
    }
  },
  methods: {
    // Wird aufgerufen, wenn sich im Eingabefeld "Zeilen pro Seite" der Wert aendert
    recalculatePages: function recalculatePages() {
      this.currentPage = 1;
      var parsedPageSize = parseInt(this.pageSize);
      if (!isNaN(parsedPageSize)) {
        if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue *** recalculate() neue Zeilenzahl = " + parsedPageSize);
        this.pageSize = parsedPageSize;
        var payLoad = {
          limit: this.pageSize,
          offset: this.currentPage,
          sortCriteria: this.sortCriteria,
          filterCriteria: this.columnFilter,
          searchCriteria: this.searchCriteria,
          multiProcessKey: ''
        };
        emit('loadProcessListPage', payLoad);
      } else {
        if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue *** recalculate() Konnte Wert pageSize [" + this.pageSize + "] nicht parsen.");
        // Setze default 10, wenn Eingabe ungueltig
        this.pageSize = 10;
      }
    },
    sort: function sort(pSortColumn) {
      if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue *** sort(" + pSortColumn + ") this.sortColumn war bisher " + this.sortColumn);
      var sortOrder = 'asc';
      if (pSortColumn === this.sortColumn) {
        // Wenn Kriterium unveraendert, kehre Sortierreihenfolge um.
        // Die Sortierkriterien sind Properties am Datenmodell "tmpVorgang"
        // in AdminApp.vue
        sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      }
      // Sortierkriterien fuer naechsten Aufruf merken
      this.sortOrder = sortOrder;
      this.sortColumn = pSortColumn;

      // Sortierbedinungen im Array fuers Event speichern
      this.$set(this.sortCriteria, 'Column', pSortColumn);
      this.$set(this.sortCriteria, 'Order', sortOrder);

      // Veranlasse neue Suchabfrage an Sign-API mit Sortierkriterien
      // Event wird von AdminApp.vue aufgefangen.
      var payLoad = {
        limit: this.pageSize,
        offset: this.currentPage,
        sortCriteria: this.sortCriteria,
        filterCriteria: this.columnFilter,
        searchCriteria: this.searchCriteria,
        multiProcessKey: ''
      };
      emit("loadProcessList", payLoad);
    },
    sortedClass: function sortedClass(pNewSortColumn) {
      // Fuer die Darstellung des Icons neben dem Spaltentitel
      if (this.sortColumn !== pNewSortColumn) {
        // Nicht gewaehlte Spalten nicht markieren
        return 'hidden icon-triangle-s';
      }
      // Beim ersten Laden der Seite greift das vorbelegte Sortierkriterium "sortByDate"
      return 'icon-triangle-' + (this.sortOrder === 'asc' ? 'n' : 's');
    },
    onPageChange: function onPageChange(pPage) {
      if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue:onPageChange, Blättere zur Seite " + pPage);
      // Setze aktuelle Seite beim Blaettern
      this.currentPage = pPage;
      // Baue Parameter fuer Eventbus
      // Event wird aufgefangen in AdminApp.vue
      var payLoad = {
        limit: this.pageSize,
        offset: pPage,
        sortCriteria: this.sortCriteria,
        filterCriteria: this.columnFilter,
        searchCriteria: this.searchCriteria,
        multiProcessKey: ''
      };
      emit("loadProcessListPage", payLoad);
    },
    showDetails: function showDetails(pProcess) {
      // Schicke Event an AdminPage...
      if (pProcess.isMultiProcess) {
        // ...zum Laden der Child-Prozesse im Popup
        emit('showPopupProcessList', pProcess);
      } else {
        // ...zum Oeffnen der Sidebar mit Vorgangsdetails
        this.$emit("details", pProcess);
      }
    },
    cancelProcess: function cancelProcess(pProcess) {
      // Dieses Event kommt nur von Einzel-Vorgaengen im Status "RUNNING", weil nur
      // bei diesen der entsprechende Button in VorgangsListenEintrag sichtbar ist
      emit('showPopupEnterCancelReasonProcess', {
        payload: pProcess,
        callback: function callback() {}
      });
    },
    refreshTable: function refreshTable() {
      // Simuliere Seitenaenderung einfach mit der aktuellen Seite
      this.onPageChange(this.currentPage);
    },
    searchProcesses: function searchProcesses(pSearchTermAndMatchType) {
      var searchTerm = pSearchTermAndMatchType.split('&')[0];
      var searchMatchType = pSearchTermAndMatchType.split('&')[1];
      this.$set(this.searchCriteria, 'Status', this.currentStatus);
      this.$set(this.searchCriteria, 'SearchTerm', searchTerm);
      this.$set(this.searchCriteria, 'SearchMatchType', searchMatchType);
      var payLoad = {
        limit: this.pageSize,
        offset: this.currentPage,
        sortCriteria: this.sortCriteria,
        filterCriteria: this.columnFilter,
        searchCriteria: this.searchCriteria,
        multiProcessKey: ''
      };
      if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue:searchProcesses (" + searchTerm + ", " + searchMatchType + ")");
      if (this.DEBUG) console.table(payLoad);
      emit("searchProcesses", payLoad);
      this.numQueryHits = this.totalResults;
    },
    // Kommt per Event vom Spalten-Suchformular, welches den Spaltennamen und den Suchbegriff mit sendet
    filterProcesses: function filterProcesses(pColumnFilter) {
      if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue:filterProcesses - pColumnFilter = " + pColumnFilter);
      // Da wir mit dem Event nur *einen* Parameter senden koennen,
      // sind Spaltenname, Suchbegriff, SearchMatchType und Quelle
      // der Suche (Popup oder Vorgangsliste) durch ein '&' verkettet
      if (!!pColumnFilter) {
        var searchColumn = pColumnFilter.split("&")[0];
        var searchTerm = pColumnFilter.split("&")[1];
        var searchMatchType = pColumnFilter.split("&")[2];
        var parentComponent = pColumnFilter.split("&")[3];
        // Setze entsprechende Werte im Array columnFilter
        this.$set(this.columnFilter, searchColumn, searchTerm);
        this.$set(this.columnFilter, 'SearchMatchType', searchMatchType);
        this.$set(this.columnFilter, 'Source', parentComponent);
        this.numFilterColumns += 1;
        var payLoad = {
          limit: this.pageSize,
          offset: this.currentPage,
          sortCriteria: this.sortCriteria,
          filterCriteria: this.columnFilter,
          searchCriteria: this.searchCriteria,
          multiProcessKey: ''
        };
        if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue *** filterProcesses() - sende Event loadProcessList mit payLoad");
        if (this.DEBUG) console.table(this.columnFilter);
        emit('loadProcessList', payLoad);
      }
    },
    filterProcessesDate: function filterProcessesDate(pFromAndToDate) {
      // Datepicker.vue fuegt die Daten als String zusammen.
      // Z.B.: "from=2023-01-30&to=2023-02-03
      if (!!pFromAndToDate) {
        var fromDate = pFromAndToDate.split("&")[0].split("=")[1];
        var toDate = pFromAndToDate.split("&")[1].split("=")[1];
        var parentComponent = pFromAndToDate.split("&")[2];
        // Setze entsprechende Werte im Gesamt-Array "columnFilter"
        this.$set(this.columnFilter, 'DateFrom', fromDate);
        this.$set(this.columnFilter, 'DateTo', toDate);
        this.$set(this.columnFilter, 'Source', parentComponent);
        this.numFilterColumns += 1;
        var payLoad = {
          limit: this.pageSize,
          offset: this.currentPage,
          sortCriteria: this.sortCriteria,
          filterCriteria: this.columnFilter,
          searchCriteria: this.searchCriteria,
          multiProcessKey: ''
        };
        emit('loadProcessList', payLoad);
      }
    },
    clearSearch: function clearSearch(pColumnFilter) {
      // Ist pColumnFilter gefuellt, kam das Event von der Spaltensuche.
      // Wenn der Parameter leer ist, kam er von dem Sucheingabefeld.
      if (!!pColumnFilter) {
        this.$set(this.columnFilter, pColumnFilter.split("&")[0], '');
        this.numFilterColumns -= 1;
        var payLoad = {
          limit: this.pageSize,
          offset: this.currentPage,
          sortCriteria: this.sortCriteria,
          filterCriteria: this.columnFilter,
          searchCriteria: this.searchCriteria,
          multiProcessKey: ''
        };
        emit('loadProcessList', payLoad);
      } else {
        // Aktuelle Seite einfach neu laden
        this.$set(this.searchCriteria, 'SearchTerm', '');
        this.$set(this.searchCriteria, 'SearchMatchType', '');
        var _payLoad = {
          limit: this.pageSize,
          offset: this.currentPage,
          sortCriteria: this.sortCriteria,
          filterCriteria: this.columnFilter,
          searchCriteria: this.searchCriteria,
          multiProcessKey: ''
        };
        // Sende Event an AdminApp.vue
        emit('clearSearch', _payLoad);
      }
    }
  },
  watch: {
    currentPage: function currentPage() {
      if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue - NEUE Seite " + this.currentPage);
    },
    currentPageProp: function currentPageProp(newPage) {
      this.currentPage = newPage;
    }
  },
  computed: {
    sortedProcessList: function sortedProcessList() {
      // Frueher wurde hier die bereits geladene Liste sortiert. Jetzt gibt es
      // stattdessen eine neue Abfrage auf die Sign-API mit dem Sortierkriterium.
      // Die Funktion setzt nur noch die neue pageSize, bevor sie die processlist
      // zurueckgibt, welche mit der Response von der Sign-API befuellt wurde.
      this.newPageSize = this.pageSize;
      return this.vorgangsliste;
    },
    indexStart: function indexStart() {
      // Erste anzuzeigende Zeilennummer
      if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue *** indexStart() rechnet mit currentPage = " + this.currentPage + " und pageSize = " + this.pageSize);
      return (this.currentPage - 1) * this.pageSize;
    },
    indexEnd: function indexEnd() {
      // Letzte anzuzeigende Zeilennummer
      if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue *** indexEnd() rechnet mit indexStart " + this.indexStart + " und pageSize = " + this.pageSize);
      return this.indexStart + this.pageSize;
    },
    currentPageString: function currentPageString() {
      // Fuer die Ausgabe im Frontend muss die Seitenzahl ein String sein
      return '' + this.currentPage;
    },
    totalPages: function totalPages() {
      var newNumPages = 1;
      // Check, ob die Ergebnisse auf eine Seite passen
      if (this.totalResults <= this.pageSize) {
        // Aktiv aktuelle Seite setzen, um indexStart und indexEnd neu zu berechnen
        this.currentPage = 1;
      } else {
        // Nimm die naechstgroesste Seitenzahl
        newNumPages = Math.ceil(this.totalResults / this.pageSize);
      }
      if (this.DEBUG) console.log("DEBUG Vorgangsliste.vue *** totalPages() pageSize = " + this.pageSize + " und totalPages = " + newNumPages);
      return newNumPages;
    },
    paginated: function paginated() {
      // Das Slicen der Liste ist obsolet. Die paginierte Liste kommt direkt von der Sign-API
      return this.sortedProcessList;
    },
    sortBy: function sortBy() {
      // Baue Kriterium und Richtung zu einem einzigen String fuer das Event
      // zusammen, das oben in sort() verschickt wird.
      return this.sortColumn + "-" + this.sortOrder.toUpperCase();
    }
  }
};