var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "signplugin__unterschrift"
  }, [_c("div", {
    staticClass: "signplugin__unterschrift__row"
  }, [_c("div", {
    staticClass: "signplugin__unterschrift__cell"
  }, [_c("label", {
    staticClass: "signplugin__unterschrift__label"
  }, [_vm._v("\n\t\t\t\tSequenz\n\t\t\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__value"
  }, [_vm._v("\n\t\t\t\t" + _vm._s(_vm.unterschrift.sequence || "unbekannt") + "\n\t\t\t")])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__cell"
  }, [_c("label", {
    staticClass: "signplugin__unterschrift__label"
  }, [_vm._v("\n\t\t\t\tStatus\n\t\t\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__value signplugin__unterschrift__value-with-icon"
  }, [_vm.unterschrift.invited !== null ? _c("EmailIcon", {
    attrs: {
      size: 20
    }
  }) : _vm._e(), _vm._v(" "), _vm.unterschrift.invited === null ? _c("PauseCircleIcon", {
    attrs: {
      size: 20
    }
  }) : _vm._e(), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.statusText))])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__row"
  }, [_c("div", {
    staticClass: "signplugin__unterschrift__cell"
  }, [_c("label", {
    staticClass: "signplugin__unterschrift__label"
  }, [_vm._v("\n\t\t\t\tName\n\t\t\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__value"
  }, [_vm._v("\n\t\t\t\t" + _vm._s(_vm.unterschrift.clearName || "-") + "\n\t\t\t")])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__cell"
  }, [_c("label", {
    staticClass: "signplugin__unterschrift__label"
  }, [_vm._v("\n\t\t\t\t" + _vm._s(_vm.smsEnabled ? "E-Mail/Rufnummer" : "E-Mail") + "\n\t\t\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__value signplugin__unterschrift__value-with-icon"
  }, [_vm.unterschrift.email !== null ? _c("EmailAtIcon") : _vm._e(), _vm._v(" "), _vm.unterschrift.email === null ? _c("MobileIcon") : _vm._e(), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.unterschrift.email !== null ? _vm.unterschrift.email : _vm.unterschrift.mobile))])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__row"
  }, [_c("div", {
    staticClass: "signplugin__unterschrift__cell"
  }, [_c("label", {
    staticClass: "signplugin__unterschrift__label"
  }, [_vm._v("\n\t\t\t\tEinladung verschickt\n\t\t\t")]), _vm._v(" "), _c("div", {
    staticClass: "signplugin__unterschrift__value"
  }, [_vm._v("\n\t\t\t\t" + _vm._s(_vm.einladungVerschickt) + "\n\t\t\t")])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };