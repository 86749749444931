import UnterschriftPending from "./UnterschriftPending";
import UnterschriftRejected from "./UnterschriftRejected";
import UnterschriftComplete from "./UnterschriftComplete";
export default {
  name: "Unterschrift",
  components: {
    UnterschriftPending: UnterschriftPending,
    UnterschriftRejected: UnterschriftRejected,
    UnterschriftComplete: UnterschriftComplete
  },
  inject: ['appInfo'],
  props: {
    unterschrift: {
      id: String | null,
      clearName: String,
      signed: String | null,
      signedTimezone: String | null,
      email: String | null,
      mobile: String | null,
      invited: String | null,
      location: String | null,
      sequence: Number | null
    } | {
      clearName: String,
      rejected: String | null,
      rejectedTimezone: String | null,
      rejectedReason: String | null,
      rejectionByCancellation: Boolean,
      email: String | null,
      mobile: String | null,
      invited: String | null,
      sequence: Number | null
    } | {
      clearName: String,
      email: String | null,
      mobile: String | null,
      invited: String | null,
      sequence: String | null
    }
  },
  computed: {
    isCompleted: function isCompleted() {
      return this.unterschrift.hasOwnProperty('id');
    },
    isRejected: function isRejected() {
      return this.unterschrift.hasOwnProperty('rejectedReason');
    },
    isPending: function isPending() {
      return !(this.isCompleted || this.isRejected);
    }
  }
};